/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  ChangeEvent,
} from 'react';

import 'react-day-picker/lib/style.css';
import { FiDownload, FiSave, FiSend } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Content, FormContainer } from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';
import Dropdown from '../../components/Dropdown';
import { Label } from '../../components/Input/styles';
import { Header, HeaderContent } from '../Feriados/styles';

interface Employee {
  id: string;
  name: string;
  email: string;
  document: string;
  phone?: string;
  zip?: string;
  street?: string;
  district?: string;
  city?: string;
  admissionDate: string | number;
  pis?: string;
  hours: string;
  interval: string;
  company: { id: string };
  company_id?: string;
  group_id?: string;
  group: { id: string };
  user: string;
  enabled: boolean;
  cargaHoraria_id?: string;
  coordenador_id?: string;
  mobile: boolean;
}
const schema = yup.object().shape({
  startDate: yup.date().required('informa data inicio'),
  endDate: yup.date().required('informa data fim'),
});

const Report: React.FC = () => {
  const { addToast } = useToast();
  const [fileName, setFileName] = useState('');
  const [visible, setVisible] = useState('hidden');
  const [opacityFile, setOpacityFile] = useState(1);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleExcelChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const data = new FormData();

      data.append('excel', e.target.files[0]);

      api.patch('register/excelFile', data).then(response => {
        setFileName(response.data);
        setVisible('visible');
        setOpacityFile(0);
        e.target.files = null;
      });
    }
  }, []);

  const downloadFileHandle = useCallback(async () => {
    const response = await api.get('register/bancoHoras', {
      responseType: 'blob',
      params: { dataFinal: dataFim, dataInicial: dataInicio },
    });
    console.log();
    const blob = new Blob([response.data], {
      type: 'text/plain',
    });
    const c = document.createElement('a');
    c.download = 'horarios.txt';
    // const url = window.URL.createObjectURL(blob);
    c.href = window.URL.createObjectURL(blob);
    c.click();
  }, [dataFim, dataInicio]);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        const arquivo = fileName;
        setVisible('hidden');
        const response = await api.post('register/reportByCompany', {
          startDate: new Date(data.startDate).getTime(),
          endDate: new Date(data.endDate).getTime(),
          fileName: arquivo,
        });
        addToast({
          type: 'success',
          title: 'Relatorio',
          description: response.data.message,
        });
        setOpacityFile(1);
        // const blob = new Blob([response.data], {
        //   type: ' application/zip',
        // });
        // const url = window.URL.createObjectURL(blob);
        // window.open(url);
      } catch (error) {
        const response = error.response.data;
        if (response.status !== 200) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: response.message,
          });
          setOpacityFile(1);
        }
      }
    },
    [addToast, fileName],
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">Relatorio</div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex row">
              <label
                className="button"
                style={{ opacity: opacityFile }}
                htmlFor="excel"
              >
                Selecione o arquivo referencia
              </label>

              <input type="file" id="excel" onChange={handleExcelChange} />
            </div>
            <div
              id="SelectDay"
              style={{
                visibility: visible === 'visible' ? 'visible' : 'hidden',
              }}
              className="flex row"
            >
              <Input
                erroMessage={errors.startDate?.message}
                error={!!errors.startDate?.message}
                reference={register('startDate')}
                label="Inicio"
                name="startDate"
                type="date"
              />
              <Input
                erroMessage={errors.endDate?.message}
                error={!!errors.endDate?.message}
                reference={register('endDate')}
                label="Fim"
                name="endDate"
                type="date"
              />

              {/* <Input
                reference={register('excel')}
                label="Fim"
                type="file"
                name="excel"
                onChange={handleExcelChange}
              /> */}
            </div>
            <div className="flex">
              <Button
                style={{
                  width: 60,
                  marginLeft: 'auto',
                  opacity: opacityFile === 1 ? 0 : 1,
                }}
                type="submit"
              >
                <FiSend size={25} color="#fff" />
              </Button>
            </div>
          </form>
          <div className="header">Banco de hora RM</div>
          <div className="flex row">
            <Input
              label="Inicio"
              name="startDateRM"
              type="date"
              onChange={e => {
                setDataInicio(e.target.value);
              }}
            />
            <Input
              label="Fim"
              name="endDateRM"
              type="date"
              onChange={e => {
                setDataFim(e.target.value);
              }}
            />
          </div>

          <div className="flex">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Baixar arquivo de banco de horas RM
            </div>
            <Button
              style={{
                width: 60,
                marginLeft: 'auto',
              }}
              type="submit"
              onClick={downloadFileHandle}
            >
              <FiDownload size={25} color="#fff" />
            </Button>
          </div>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Report;
