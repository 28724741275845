import styled, { css } from 'styled-components';

interface ShowSubstituition {
  Show: boolean;
}

export const Container = styled.div<ShowSubstituition>`
  display: flex;
  backdrop-filter: blur(0px);
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  position: fixed;
  transition: all 0.6s;
  opacity: 0;
  top: 0;
  z-index: -100;

  background-color: rgba(0, 0, 0, 0.5);

  .container {
    display: flex;
    align-items: center;
    height: 25px;
  }

  ${props =>
    props.Show &&
    css`
      z-index: 999999;
      opacity: 1;
      backdrop-filter: blur(5px);
    `};
`;
export const Modal = styled.div<ShowSubstituition>`
  width: 600px;
  min-height: 350px;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #000;
  position: absolute;
  background-color: var(--panelColor);
  top: -100%;
  transition: top ease-in-out;

  ${props =>
    props.Show &&
    css`
      top: unset;
      opacity: 1;
    `};
`;
