import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div``;

export const Content = styled.main`
  height: calc(100vh - 60px);
  padding: 0;
  margin-left: 240px;
  display: flex;
  flex-direction: column;

  .panels {
    display: flex;
    flex-direction: column;
  }

  @media (max-height: 850px) {
    .panels {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 20px;

      > div {
        margin: auto;
      }
    }
  }
`;

export const Section = styled.section`
  margin-top: 48px;

  > strong {
    color: #999591;
    font-size: 20px;
    line-height: 26px;
    border-bottom: 1px solid #3e3b47;
    display: block;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  > p {
    color: #999591;
  }
`;

export const Cabecalho = styled.div`
  color: #fff;
  padding: 15px 0;
`;

export const PainelPendencia = styled.div<{ show?: boolean }>`
  display: none;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;

  > div {
    display: flex;
    z-index: 9000;
    justify-content: space-between;
    flex-direction: column;
    width: 400px;
    border-radius: 10px;
    background-color: #3b3e47;
    padding: 10px;
    box-shadow: 1px 1px 10px #3b3e47;
    transition: all 0.7s;
    animation: pulse-red 2s infinite;
    visibility: hidden;
    @keyframes pulse-red {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
      }

      50% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(255, 0, 0, 0.1);
      }

      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.1);
      }
    }

    ${props =>
      props.show &&
      css`
        visibility: visible;
      `}

    .datas {
      display: flex;
      flex-direction: column;
      max-height: 100px;
      overflow-x: auto;
    }
  }
  ${props =>
      props.show &&
      css`
        display: flex;
      `}

`;

export const PainelRegistro = styled.div<{ extraHour?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 50px;

  > div {
    display: flex;
    z-index: 9000;
    justify-content: space-between;
    flex-direction: column;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #3b3e47;
    padding: 10px;
    box-shadow: 1px 1px 10px #3b3e47;
    transition: all 0.7s;
    animation: pulse-black 2s infinite;

    @keyframes pulse-black {
      0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
      }

      50% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
      }

      100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      }
    }

    ${props =>
      props.extraHour &&
      css`
        &:hover {
          width: 800px;
          border-radius: 5px;

          .justificativa {
            display: block;

            textarea {
              width: 100%;
            }
          }

          h1 {
            font-size: 1rem;
          }
        }
      `}

    & + div {
      margin-left: 15px;
    }

    .header {
      margin-top: 5px;
      color: #737580;
      font-size: 12px;
    }

    .gray-color {
      color: #737580;
    }
    .justificativa {
      justify-content: start;
      display: none;
      padding: 0 20px;
    }

    button:disabled {
      cursor: not-allowed;
    }

    div {
      margin-left: 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;

      h1 {
        font-size: 4rem;
      }
    }
  }
`;

export const RegistersDay = styled.div`
  height: 100px;

  .header {
    backdrop-filter: blur(12);
  }

  & > div {
    position: absolute;
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 20px 25px 30px 30px 30px;
    border-radius: 5px;

    margin-top: 15px;
    display: grid;
    z-index: 9000;
    margin: 0 30px;
    width: Calc(100% - 60px);
    bottom: 30px;
    background-color: var(--panelColor);
    > div {
      border: 1px solid #ccc;
    }
    div {
      padding: 5px;

      a {
        font-size: 14px;
        color: #fff;
        text-decoration: none;
      }
      span {
        font-size: 14px;
        color: #737580;
      }
      .substituir {
        font-size: 14px;
        padding: 0px 5px;
      }
    }
  }
`;
