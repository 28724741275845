/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';

import 'react-day-picker/lib/style.css';

import format from 'date-fns/format';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiSearch } from 'react-icons/fi';
import {
  Container,
  Header,
  HeaderContent,
  Content,
  ActionContainer,
  SearchBox,
  FormContainer,
  SubstituicaoItem,
  BodyListSubstituicao,
  SubstituicaoHeader,
  PanelSubstituicao,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import SideBar from '../../components/SideBar';
import Button from '../../components/Button';

interface Employee {
  id: string;
  name: string;
}

interface Registers {
  date: Date;
  id: string;
}

interface Ocurrence {
  description: string;
  type: number;
}

interface Subistituicao {
  id: string;
  date: Date;
  employee: Employee;
  extraHour: boolean;
  latitude: string;
  longitude: string;
  registersValid: Registers[];
  substituition_id: string;
  ocurrence: Array<Ocurrence>;
  ip: string;
}

const Submissions: React.FC = () => {
  const { addToast } = useToast();

  const [subistituicao, setSubistituicao] = useState<Subistituicao[]>([]);
  const [subistituicaoFilter, setSubistituicaoFilter] = useState<
    Subistituicao[]
  >([]);

  const schema = yup.object().shape({
    name: yup.string(),
    date: yup.date(),
  });

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const order = useCallback((list: Subistituicao[]) => {
    const filterSubstituicao = list.sort((a, b) => {
      if (a.employee.name > b.employee.name) {
        return 1;
      }
      if (a.employee.name < b.employee.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return filterSubstituicao;
  }, []);

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get('register/submission/list');
      setSubistituicao(response.data);
      const orderSubmissions = order(response.data);
      setSubistituicaoFilter(orderSubmissions);
    }
    request();
  }, []);

  const removeItemList = useCallback(
    id => {
      const listFilter = subistituicaoFilter.filter(item => item.id !== id);
      const list = subistituicao.filter(item => item.id !== id);
      setSubistituicao(list);
      const orderSubmissions = order(listFilter);
      setSubistituicaoFilter(orderSubmissions);
    },
    [subistituicaoFilter, subistituicao, order],
  );

  const handleReprove = useCallback(
    async (id: string, employee_id: string, date: Date) => {
      try {
        const response = await api.post('register/submission/reprove', {
          id,
          employee_id,
          date,
        });

        response.status >= 200 && response.status <= 204
          ? removeItemList(id)
          : null;

        addToast({
          title: 'Reprovado',
          type: 'success',
          description:
            'Você reprovou o pedido de substituição feito pelo funcionario',
        });
      } catch (error) {
        addToast({
          title: 'Reprovado',
          type: 'error',
          description:
            'ocorreu um erro interno verifique com o suporte tecnico',
        });
      }
    },
    [addToast, removeItemList],
  );
  const [totalAprove, setTotalAprove] = useState(0);
  const handleAprove = useCallback(
    async (id: string, employee_id: string, date: Date) => {
      try {
        const response = await api.post('register/submission/aproval', {
          id,
          employee_id,
          date,
        });
        response.status >= 200 && response.status <= 204
          ? removeItemList(id)
          : null;
        setTotalAprove(totalAprove + 1);
        let timeout = setTimeout(() => {
          addToast({
            title: 'Aprovado',
            type: 'success',
            description: `Você Aprovou o pedido de substituição feito pelo funcionario`,
          });
          setTotalAprove(0);
        }, 2000);
        if (totalAprove > 0) {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            addToast({
              title: 'Aprovado',
              type: 'success',
              description: `Você Aprovou ${totalAprove} de substituição feito pelo funcionario`,
            });
            setTotalAprove(0);
          }, 2000);
        }
      } catch (error) {
        addToast({
          title: 'Aprovado',
          type: 'error',
          description:
            'ocorreu um erro interno verifique com o suporte tecnico',
        });
      }
    },
    [addToast, removeItemList, totalAprove],
  );
  const handleFindSubstituicao = useCallback(() => {
    const values = getValues();
    let filterSubstituicao;
    if (values.name && values.date === '') {
      filterSubstituicao = subistituicao.filter(substituicaoItem =>
        substituicaoItem.employee.name
          .toLowerCase()
          .includes(values.name.toLowerCase()),
      );
    } else if (values.name === '' && values.date !== '') {
      filterSubstituicao = subistituicao.filter(
        substituicaoItem =>
          new Date(substituicaoItem.date) >=
            new Date(`${values.date} 00:00:00z`) &&
          new Date(substituicaoItem.date) <=
            new Date(`${values.date} 23:59:00z`),
      );
    } else if (values.name !== '' && values.date !== '') {
      filterSubstituicao = subistituicao.filter(
        substituicaoItem =>
          new Date(substituicaoItem.date) >=
            new Date(`${values.date} 00:00:00z`) &&
          new Date(substituicaoItem.date) <=
            new Date(`${values.date} 23:59:00z`) &&
          substituicaoItem.employee.name
            .toLowerCase()
            .includes(values.name.toLowerCase()),
      );
    } else {
      filterSubstituicao = subistituicao;
    }

    filterSubstituicao = order(filterSubstituicao);

    setSubistituicaoFilter(filterSubstituicao);
  }, [subistituicao, getValues, order]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <ActionContainer>
            <SearchBox>
              <form>
                <div className="flex row">
                  <Input
                    placeholder="Buscar por nome"
                    error={false}
                    style={{ width: 'auto' }}
                    name="name"
                    reference={register('name')}
                  />

                  <Input
                    type="date"
                    name="date"
                    error={false}
                    reference={register('date')}
                    style={{ width: 'auto' }}
                  />
                </div>
                <div className="flex">
                  <Button
                    style={{ width: 60, marginLeft: 'auto' }}
                    type="button"
                    onClick={handleFindSubstituicao}
                  >
                    <FiSearch size={25} color="#fff" />
                  </Button>
                </div>
              </form>
            </SearchBox>
          </ActionContainer>
          <div className="header">Pedidos em Avaliação</div>

          <PanelSubstituicao>
            <SubstituicaoHeader>
              <div>Nome</div>
              <div>Registros</div>
              <div>Data</div>
              <div>É Hora Extra?</div>
              <div>Justificativa</div>
              <div>Aprovar</div>
            </SubstituicaoHeader>
            <BodyListSubstituicao>
              <div className="body">
                {subistituicaoFilter &&
                  subistituicaoFilter.map(sub => {
                    return (
                      <SubstituicaoItem key={sub.id}>
                        <div>
                          <div>{sub.employee.name}</div>
                          <div className="valid">
                            <div>
                              <b>entrada</b>
                            </div>
                            <div>
                              <b>saida</b>
                            </div>
                            {sub.registersValid.map(item => {
                              return (
                                <div key={item.id}>
                                  {item.id === sub.substituition_id ? (
                                    <span className="label">
                                      {format(new Date(item.date), 'HH:mm')}
                                    </span>
                                  ) : (
                                    <span>
                                      {format(new Date(item.date), 'HH:mm')}
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <div>
                            {format(new Date(sub.date), 'dd/MM/yyyy HH:mm')}
                          </div>
                          <div>{sub.extraHour ? 'Sim' : 'Não'}</div>
                          <div>
                            {sub.ocurrence?.map(ocurrence => {
                              return (
                                <>
                                  {ocurrence.description}
                                  {ocurrence.type === 3
                                    ? ` IP: ${sub.ip} no local aproximado`
                                    : ''}
                                  {ocurrence.type === 3 ? (
                                    <a
                                      href={`https://www.google.com/maps/@${sub.latitude},${sub.longitude},17z`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      abrir no mapa
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                  <br />
                                </>
                              );
                            })}
                          </div>
                          <div>
                            <button
                              className="success"
                              type="button"
                              onClick={() => {
                                handleAprove(sub.id, sub.employee.id, sub.date);
                              }}
                            >
                              <FaRegThumbsUp />
                            </button>
                            <button
                              className="danger"
                              type="button"
                              onClick={() => {
                                handleReprove(
                                  sub.id,
                                  sub.employee.id,
                                  sub.date,
                                );
                              }}
                            >
                              <FaRegThumbsDown />
                            </button>
                          </div>
                        </div>
                      </SubstituicaoItem>
                    );
                  })}
              </div>
            </BodyListSubstituicao>
          </PanelSubstituicao>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Submissions;
