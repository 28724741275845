import { shade } from 'polished';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    --panelColor: #3b3e47;
    --borderInPanel: #30333c;
    --labelColor: #ff9000;
    --alternateColorRow: #42454e;
    --alternateColorRowEven:#30333c;
    --buttonBackgroundColor:#f5f5f5;
    --buttonColor: #fff;
  }

  ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--labelColor);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}

  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

  body {
    background: #312E38;
    color: #FFF;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Roboto', serif;
    font-size: 16px;

    @media(max-width: 1370px){
      font-size: 12px;
    }
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }
  input:-internal-autofill-selected {
    background-color: #000 ;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  width: 100% !important;
}
.justify-end
{
  justify-content: flex-end
}
.space-between {
  justify-content: space-between;
}
.row {
  flex-direction: row;
  padding: 8px 0;
  max-width: 100%;
  position: relative;

.col-2
{
  margin-left: 15px;
  min-width: 20%;
  max-width: 20%;
  & + div.col-2 > div.col-2
  {
    margin-left: -15px;
  }
}

.label {
  color: var(--labelColor) !important;
}
.p-1 {
  padding: 5px
}
.pl-1 {
  padding-left: 5px
}
.pr-1 {
  padding-right: 5px
}
.p-2 {
  padding: 15px
}
.pl-2 {
  padding-left: 15px
}
.pr-2 {
  padding-right: 15px
}

  div {
    flex: 1;
    & + div.flex {
      margin-left: -15px;
    }
    & + div {
      padding-left: 15px;
    }
  }
}
.center{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items:center;
}
button {
    margin: 10px 0;
    background-color: #f5f5f5;
    border: 0px solid transparent;
    border-radius: 5px;
    color: #161616;
    padding: 5px 20px;
    transition: background-color 0.3s;
margin-right:5px;
  }



  button[type=submit] {
    background-color: #00b503;
    color: #fff;
  }

  button[type=submit]:hover {
    background-color: ${shade(0.25, '#00b503')};
  }
  button[type=submit]:disabled {
    color: ${shade(0.75, '#fff')};
    background-color: ${shade(0.75, '#00b503')};
  }
  .success {
  background-color: #00b503 !important;
}
.danger {
  background-color: #ff0000 !important;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-left: 10px;
  margin-top: 5px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: var(--labelColor);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.align-bottom {
  align-items: flex-end;
  display: flex;
}

.botaoNovoRegistro {
  z-index: 9999;
  width: 200px !important;
  bottom: 160px;
  height:40px;
  position: absolute;
  left: Calc(50% - 100px);
}

.dropdown-menu {
    position: absolute;
    top: 65px;
    z-index: 1000;
    display: none;
    float: left;
    list-style: none;
    text-shadow: none;
    padding: 7px 0;
    margin: 10px 0 0;
    background-color: #fff;
    border: 1px solid #e5e9ec;
    -webkit-border-radius: 0!important;
    -moz-border-radius: 0!important;
    -ms-border-radius: 0!important;
    -o-border-radius: 0!important;
    border-radius: 2px!important;
    -webkit-box-shadow: 0 5px 5px -5px rgba(0,0,0,.1);
    -moz-box-shadow: 0 5px 5px -5px rgba(0,0,0,.1);
    box-shadow: 0 5px 5px -5px rgba(0,0,0,.1)
}

.dropdown-menu:after,.dropdown-menu:before {
    position: absolute;
    display: inline-block!important;
    content: '';
    right: auto
}

.dropdown-menu li {
    font-size: 14px
}
.dropdown.open .dropdown-menu {
 display: block;
}

.navbar-right .dropdown-menu {
    right: 65px;
    left: auto;
}

.dropdown-menu li a:hover {
    background: #f3f3f3;
    text-decoration: none
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 25px!important
}

.dropdown-menu:before {
    top: -8px;
    left: 9px;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #e5e9ec;
    border-left: 8px solid transparent
}

.dropdown-menu.title-caret:after,.dropdown-menu:after {
    border-bottom: 7px solid #fff
}

.dropdown-menu:after {
    top: -7px;
    left: 10px;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent
}

.btn-group.dropup>.dropdown-menu:after,.btn-group.dropup>.dropdown-menu:before,.dropdown-toggle.dropup>.dropdown-menu:after,.dropdown-toggle.dropup>.dropdown-menu:before,.dropdown.dropup>.dropdown-menu:after,.dropdown.dropup>.dropdown-menu:before {
    display: none!important
}

.dropdown-menu.dropdown-menu-left:before,.navbar-left .dropdown-menu:before {
    left: 16px;
    right: auto
}

.dropdown-menu.dropdown-menu-left:after,.navbar-left .dropdown-menu:after {
    left: 17px;
    right: auto
}

.dropdown-menu.dropdown-menu-right:before,.navbar-right .dropdown-menu:before {
    right: 16px;
    left: auto
}

.dropdown-menu.dropdown-menu-right:after,.navbar-right .dropdown-menu:after {
    right: 17px;
    left: auto
}

.dropdown-menu>li.active>a,.dropdown-menu>li.active>a:active,.dropdown-menu>li.active>a:focus,.dropdown-menu>li.active>a:hover {
    background: #f3f3f3;
    color: #2b2b2b
}

.drop-title {
    display: block;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #e5e9ec;
    background: #fff;
    padding: 12px;
    color: #6a6a6a;
    margin: 0
}

.drop-title-link {
    float: right;
    display: inline!important;
    padding: 0!important;
    width: 18px;
    overflow: hidden;
    vertical-align: text-top;
    line-height: 18px!important;
    border-radius: 100%
}

.drop-title-link i {
    margin-right: 0!important
}

.dropdown-menu li.drop-all a {
    padding: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #707070;
    background: #FFF;
    font-size: 14px
}

.dropdown-menu li.drop-all a:hover {
    background: #f3f3f3
}

.dropdown-sm {
    width: 190px
}

.dropdown-md {
    width: 230px
}

.dropdown-lg {
    width: 280px
}

.dropdown-content {
    padding-top: 0;
    padding-bottom: 0
}

.dropdown-menu li a {
    padding: 8px 15px;
    color: #5f5f5f;
    font-size: 13px;
    display: block
}

.dropdown-menu li a i {
    margin-right: 10px
}

.dropdown-menu li.no-link {
    padding: 7px 10px
}

.dropdown-menu li.li-group {
    border-bottom: 1px solid #E5E9EC;
    padding: 5px 0
}

.nav>li>a,.right-sidebar-top .nav>li>a {
    padding: 10px 15px
}

.nav .open>a,.nav .open>a:focus,.nav .open>a:hover {
    background-color: #eee;
    border-color: transparent
}

.page-right-sidebar-inner .tab-content {
    margin-top: 0
}

.navbar .dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    -ms-transition: all .3s ease;
    margin-top: 40px!important
}

.navbar .dropdown .dropdown-menu.dropdown-list li:first-child {
    margin-top: 5px
}

.navbar .dropdown .dropdown-menu.dropdown-list li:last-child {
    margin-bottom: 5px
}

.navbar .dropdown .dropdown-menu.dropdown-list.theme-settings li:first-child {
    margin-top: 0
}

.navbar .open .dropdown-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 15px!important;
    border-radius: 2px!important
}

.navbar .dropdown {
    display: inline-block
}

.dropdown-notifications {
    height: auto!important;
    max-height: 250px
}

.dropdown-notifications .dropdown-oc li a {
    padding-right: 0;
    border-bottom: 1px solid #e5e9ec
}

.dropdown-notifications .dropdown-oc li a span.notification-badge {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 100%;
    display: inline-block;
    vertical-align: top;
    margin-top: 10px
}

.dropdown-notifications .dropdown-oc li a span.notification-badge i {
    margin-right: 0
}

.dropdown-notifications .dropdown-oc li a span.notification-info {
    width: 204px;
    padding: 4px 5px;
    display: inline-block;
    vertical-align: middle;
    font-weight: 400
}

.dropdown-notifications .dropdown-oc li a span .notification-date {
    padding: 5px
}

.dropdown-notifications .dropdown-oc li a span.notification-info span {
    margin-bottom: 0
}

.dropdown-notifications .dropdown-oc li a span.notification-info small {
    color: #aaa
}

table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
    table-layout: fixed;
}
thead {
    display: table-header-group;
    vertical-align: middle;
    border-color: inherit;
    background-color: #f5f5f5;

  }
th {
  font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.40625rem;
    display: table-cell;

    vertical-align: inherit;
    border-bottom: 1px solid rgb( 231 227 252 / 0.12);
    text-align: left;
    padding: 16px;
    color: var(--mui-palette-text-primary);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #232129;
    min-width: 170px;
}

td {
    text-align: left;
    padding: 8px;
}

td > button {
  background-color: transparent
}
tr {
  transition: background-color 0.3s;
}
tr:nth-child(even) {background-color: var(--alternateColorRowEven)}
tr:hover{
  background-color: #3b3b47;
}
.gap-1 {
  gap: 8px
}
/* reset */


.AlertDialogOverlay {
  background-color: var(--black-a9);
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.AlertDialogContent {
  background-color: white;
  color: #000;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}
.AlertDialogContent:focus {
  outline: none;
}

.AlertDialogTitle {
  margin: 0;
  color: var(--mauve-12);
  font-size: 17px;
  font-weight: 500;
}

.AlertDialogDescription {
  margin-bottom: 20px;
  color: var(--mauve-11);
  font-size: 15px;
  line-height: 1.5;
}


@keyframes overlayShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes contentShow {
  from {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}
`;
