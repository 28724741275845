import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';

const Download: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get('register/download', {
        responseType: 'blob',
      });
      const blob = new Blob([response.data], {
        type: ' application/zip',
      });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
      history.push('/');
    }
    request();
  }, []);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      Baixando e redirecionando
    </div>
  );
};

export default Download;
