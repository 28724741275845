import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers:{'Access-Control-Allow-Origin': '*',}
});


export const microsoftApi = axios.create({
  baseURL: 'https://dev.virtualearth.net/REST/v1/Locations/',
  params: {
    key: 'AkIwcTlFRWgATB5Z289N_ANFHWUexn7zKK9EbCOVFmHEI4wfdKTKf_chP9Y6PlUr',
  },
});

microsoftApi.interceptors.request.use(config => {
  const config2 = config;
  config2.params = config.params || {};
  config2.params.key =
    'AkIwcTlFRWgATB5Z289N_ANFHWUexn7zKK9EbCOVFmHEI4wfdKTKf_chP9Y6PlUr';
  return config2;
});

export default api;
