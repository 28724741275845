/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import 'react-day-picker/lib/style.css';
import { parse, isDate } from 'date-fns';
import { FiEdit, FiPlus, FiToggleLeft, FiToggleRight } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import { NONAME } from 'dns';
import {
  Container,
  Header,
  HeaderContent,
  Content,
  PanelEmployee,
  ActionContainer,
  SearchBox,
  BodyListEmployee,
  EmployeeItem,
  FormContainer,
  EmployeeHeader,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';

interface Groups {
  id: string;
  name: string;
}

interface Employee {
  id?: string;
  name: string;
  email: string;
  document: string;
  phone?: string;
  zip?: string;
  street?: string;
  district?: string;
  city?: string;
  admissionDate: string | number;
  pis?: string;
  hours: string;
  interval: string;
  company: { id: string };
  company_id?: string;
  group_id?: string;
  group: { id: string };
  user: string;
  enabled: boolean;
  cargaHoraria_id?: string;
  coordenador_id?: string;
  mobile: boolean;
  codigo?: number;
}

interface CargaHoraria {
  id: string;
  name: string;
}

interface Company {
  id: string;
  name: string;
}
// const schema = yup.object().shape({
//   name: yup.string().required('Digite o nome do funcionario'),
//   email: yup
//     .string()
//     .required('Digite o e-mail do funcionario')
//     .email('e-mail invalido'),
//   document: yup.string().required('Digite o CPF do funcionario').min(11),
//   cargaHoraria_id: yup
//     .string()
//     .required('Informe a carga horaria que o funcionario de seguir'),

//   interval: yup.number().required('Informe o tempo de intervalo'),
//   company_id: yup.string().required('Informe onde o funcionario vai trabalhar'),
//   group_id: yup.string().required('Informe o tipo de usuario'),
//   admissionDate: yup
//     .string()
//     .required('Informe a data de admissão do funcionario'),
//   coordenado_id: yup.string(),
//   mobile: yup.string(),
// });
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const Dashboard: React.FC = () => {
  const { addToast } = useToast();
  const query = useQuery();
  const history = useHistory();

  const [groups, setGroups] = useState<Groups[]>([]);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [coordenador, setCoordenador] = useState<Employee[]>([]);
  const [company, setCompany] = useState<Company[]>([]);
  const [salvando, setSalvando] = useState(false);
  const [ativo, setAtivo] = useState(true);

  const [filtredEmployees, setFiltredEmployees] = useState<Employee[]>([]);
  const [cargaHoraria, setCargaHoraria] = useState<CargaHoraria[]>([]);
  const [employeeEdit, setEmployeeEdit] = useState<Employee>({
    name: '',
    email: '',
    document: '',
    admissionDate: format(new Date(), 'yyyy-MM-dd'),
    hours: '',
    interval: '',
    company: { id: '' },
    group: { id: '' },
    user: '',
    enabled: true,
    mobile: false,
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get<Array<Employee>>('/employee?all=true');
      setEmployees(response.data);
      setFiltredEmployees(response.data.filter(item => item.enabled === true));

      const responseCargaHoraria = await api.get('/hora');
      setCargaHoraria(responseCargaHoraria.data);
      const responseGroup = await api.get('/group');
      setGroups(responseGroup.data);
      const responseCoordenador = await api.get('/employee/coordenadores');
      if (responseCoordenador.data) {
        setCoordenador(responseCoordenador.data);
      }

      const responseCompany = await api.get('/company');
      if (responseCompany.data) {
        setCompany(responseCompany.data);
      }
    }
    request();
  }, []);

  const permissoes = useMemo(() => {
    return groups.map<{ value: string; text: string }>(groupItem => {
      return {
        value: groupItem.id,
        text: groupItem.name,
      };
    });
  }, [groups]);

  const coordenadoresValues = useMemo(() => {
    return coordenador.map<{ value: string; text: string }>(coordenadorItem => {
      return {
        value: coordenadorItem.id || '',
        text: coordenadorItem.name,
      };
    });
  }, [coordenador]);

  const handleFindEmployee = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      const filterEmployee = employees.filter(
        employee =>
          employee.name
            .toLowerCase()
            .includes(value.target.value.toLowerCase()) &&
          employee.enabled === ativo,
      );
      setFiltredEmployees(filterEmployee);
    },
    [employees, ativo],
  );

  const handleActive = useCallback(
    async (id: string, enabled: boolean) => {
      try {
        const response = await api.patch('employee/enable', { id, enabled });
        debugger;
        const employeeIndex = employees.findIndex(
          employee => employee.id === id,
        );
        const employee = { ...employees[employeeIndex], enabled };

        employees[employeeIndex] = employee;

        setEmployees(employees);
        if (response.status === 200) {
          addToast({
            type: 'success',
            title: 'Salvo com sucesso',
            description: 'Funcionario foi salvo com sucesso. :)',
          });
        }
      } catch {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar',
          description:
            'Ocorreu um erro ao atualizar o funcionario tente novamente mais tarde :(',
        });
      }
    },
    [employees, addToast],
  );
  const handleAtivoList = useCallback(
    event => {
      setAtivo(event.target?.checked);
      setFiltredEmployees(
        employees.filter(item => item.enabled === event.target?.checked),
      );
    },
    [employees],
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">
            <span>Lista de funcionarios</span>
            <Link
              to="/employee/createOrEdit"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                color: '#fff',
                textDecoration: 'none',
                width: 'fit-content',
                marginBottom: 10,
              }}
            >
              <FiPlus size={25} color="#fff" /> Criar Novo Funcionario
            </Link>
          </div>
          <PanelEmployee>
            <div style={{ display: 'flex', justifyContent: 'end' }} />
            <ActionContainer>
              <SearchBox>
                <Input
                  containerStyle={{ flexGrow: 1 }}
                  name="search"
                  placeholder="Buscar por nome"
                  error={false}
                  onChange={handleFindEmployee}
                  style={{ width: 'auto' }}
                />

                <label className="container">
                  <input
                    type="checkbox"
                    checked={ativo}
                    onChange={handleAtivoList}
                    name="ativo"
                  />
                  Ativo
                  <span className="checkmark" />
                </label>
              </SearchBox>

              {/* <a href="#" >

              </a> */}
            </ActionContainer>

            <EmployeeHeader>
              <div>Nome</div>
              <div>CPF</div>
              <div>MATRÍCULA</div>
              <div>ativar/desativar</div>
              <div />
            </EmployeeHeader>
            <BodyListEmployee>
              <div className="body">
                {filtredEmployees ? (
                  filtredEmployees.map(employee => {
                    return (
                      <EmployeeItem key={employee.id}>
                        <div>{employee.name}</div>
                        <div>
                          {employee.document
                            .replaceAll('.', '')
                            .replace(
                              /^(\d{3})(\d{3})(\d{3})(\d{2}).*/,
                              '$1.$2.$3-$4',
                            )}
                        </div>
                        <div>{employee.codigo}</div>
                        <div>
                          <button
                            type="button"
                            onClick={() => {
                              if (employee.id) {
                                handleActive(employee.id, !employee.enabled);
                              }
                            }}
                          >
                            {employee.enabled ? (
                              <FiToggleRight size={15} color="#0d8000" />
                            ) : (
                              <FiToggleLeft size={15} color="#ff0000" />
                            )}
                          </button>
                        </div>
                        <div>
                          <Link to={`/employee/createOrEdit?id=${employee.id}`}>
                            <FiEdit size={15} color="#fff" />
                          </Link>
                        </div>
                      </EmployeeItem>
                    );
                  })
                ) : (
                  <div>Carregando...</div>
                )}
              </div>
            </BodyListEmployee>
          </PanelEmployee>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Dashboard;
