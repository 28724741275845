/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
import { yupResolver } from '@hookform/resolvers/yup';
import { setMinutes } from 'date-fns';
import { setHours } from 'date-fns/esm';
import format from 'date-fns/format';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import Dropdown from '../Dropdown';
import Input from '../Input';
import TextArea from '../TextArea';
import { Container, Modal } from './styles';

interface Register {
  show: boolean;
  dataHora?: Date;
  id?: string;
  longitude?: number;
  latitude?: number;
  closeFunction(): void;
}

const Substituicao: React.FC<Register> = ({
  show,
  dataHora,
  id,
  longitude,
  latitude,
  closeFunction,
}) => {
  const schema = yup.object().shape({
    hora: yup
      .number()
      .min(0, 'Informe uma hora valida')
      .max(23, 'Informe uma hora valida')
      .required('Digite a hora'),
    minuto: yup
      .number()
      .min(0, 'Informe uma hora valida')
      .max(59, 'Informe uma hora valida')
      .required('Digite a minuto'),
    description: yup.string(),
    data: yup.string(),
    atestado: yup.boolean(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [horaExtra, setHoraExtra] = useState(false);
  const [type, setType] = useState(2);

  const { addToast } = useToast();

  useEffect(() => {
    setValue('data', dataHora);
  }, [setValue, dataHora]);

  const onSubmit = useCallback(
    async (dataValue, event) => {
      debugger;
      const buttonSubmit = event.target.querySelector("[type='submit']");
      try {
        buttonSubmit.disabled = true;
        let dataUpdate = dataHora;
        if (!dataHora) {
          dataUpdate = new Date(`${dataValue.data}T00:00:00`);
        }
        if (!dataUpdate) {
          addToast({
            title: 'Error',
            type: 'error',
            description: 'Informe a data',
          });
          return;
        }

        let novaData = setHours(new Date(dataUpdate), dataValue.hora);
        novaData = setMinutes(novaData, dataValue.minuto);
        const submitData = {
          substituition_id: id,
          data: novaData,
          description: dataValue.description,
          longitude,
          latitude,
          type: dataValue.atestado ? 5 : type,
        };
        addToast({
          title: 'Carregando',
          type: 'info',
          description: 'Enviando',
        });
        await api.post('register/submission', submitData);
        buttonSubmit.disabled = false;
        reset();
        addToast({
          type: 'success',
          title: 'salvo com sucesso',
          description: 'Registro salvo aguarde aprovação pelo seu coordenador',
        });
        setHoraExtra(false);
        closeFunction();
      } catch (error: any) {
        const response = error.response.data;
        buttonSubmit.disabled = false;
        if (
          response.message ===
          'Você deve informar uma justificativa por esta fazendo hora extra'
        ) {
          setHoraExtra(true);
          setType(1);
        }
        addToast({
          type: 'error',
          title: 'ocorreu um erro',
          description:
            response.message ||
            'Ocorreu um erro ao solicitar substituição tente novamente',
        });
      }
    },
    [longitude, latitude, id, dataHora, closeFunction, addToast, type],
  );

  return (
    <Container Show={show}>
      <Modal Show={show}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {dataHora ? (
            <div>
              Registro atual: {format(new Date(dataHora), 'dd/MMM/yyyy HH:mm')}
              <Input
                label="Data"
                erroMessage={errors.data?.message}
                error={!!errors.data?.message}
                containerStyle={{ display: 'none' }}
                type="date"
                reference={register('data')}
                name="data"
              />
            </div>
          ) : (
            <div>
              <div className="flex row">
                <Input
                  label="Data"
                  erroMessage={errors.data?.message}
                  error={!!errors.data?.message}
                  type="date"
                  reference={register('data')}
                  name="data"
                />
              </div>
            </div>
          )}
          <div className="flex row">
            <Dropdown
              data={[
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
              ].map(hora => {
                return { value: `${hora}`, text: `${hora}` };
              })}
              erroMessage={errors.hora?.message}
              error={!!errors.hora?.message}
              placeholder="Hora"
              label="Hora"
              reference={register('hora')}
              name="hora"
            />
            <Dropdown
              data={[
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10,
                11,
                12,
                13,
                14,
                15,
                16,
                17,
                18,
                19,
                20,
                21,
                22,
                23,
                24,
                25,
                26,
                27,
                28,
                29,
                30,
                31,
                32,
                33,
                34,
                35,
                36,
                37,
                38,
                39,
                40,
                41,
                42,
                43,
                44,
                45,
                46,
                47,
                48,
                49,
                50,
                51,
                52,
                53,
                54,
                55,
                56,
                57,
                58,
                59,
              ].map(hora => {
                return { value: `${hora}`, text: `${hora}` };
              })}
              reference={register('minuto')}
              label="Minuto"
              erroMessage={errors.minuto?.message}
              error={!!errors.minuto?.message}
              placeholder="Minuto"
            />
          </div>
          <div>
            <label className="container">
              <input {...register('atestado')} type="checkbox" />
              Atestado
              <span className="checkmark" />
            </label>
          </div>
          <div
            className="row"
            style={{
              display: horaExtra ? 'block' : 'none',
              color: 'red',
              paddingLeft: '10px',
              marginBottom: 0,
            }}
          >
            Informe uma jsutificativa para hora extra
          </div>
          <div className="row">
            <TextArea
              label="Justificativa"
              erroMessage={errors.description?.message}
              error={!!errors.description?.message}
              reference={register('description')}
            />
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={closeFunction}>
              Sair
            </button>
            <button type="submit">Submeter</button>
          </div>
        </form>
      </Modal>
    </Container>
  );
};
export default Substituicao;
