import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isAdmin?: boolean;
  isCoord?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  isAdmin = false,
  component: Component,
  ...rest
}) => {
  const { user, employee } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        if (isPrivate === !!user) {
          if (isAdmin) {
            if (employee.group.id === 'eee571c7-b7eb-4b13-a4e5-70aa1c4bf4ce') {
              return <Component />;
            }
            if (
              employee.group.id === 'eee571c7-b7eb-4b13-a4e5-70aa1c4bf4ce' ||
              employee.group.id === '077d9a30-f134-431f-a552-570657640070'
            ) {
              return <Component />;
            }
            return (
              <Redirect
                to={{
                  pathname: isPrivate ? '/' : '/dashboard',
                  state: { from: location },
                }}
              />
            );
          }
          return <Component />;
        }

        return (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
