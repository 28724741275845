/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import 'react-day-picker/lib/style.css';
import { FiEdit, FiPlus, FiSave, FiTrash } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  Header,
  HeaderContent,
  Content,
  ActionContainer,
  SearchBox,
  FormContainer,
  PanelIps,
  FeriadoHeader,
  BodyListFeriados,
  FeriadosItem,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';
import Dropdown from '../../components/Dropdown';

interface Feriado {
  id: string;
  name: string;
  dia: number;
  mes: number;
  ano: number;
  recorrencia: boolean;
  empresa: string;
  company?: { name: string; id: string };
}

interface Company {
  id: string;
  name: string;
}

const schema = yup.object().shape({
  name: yup.string().required('Digite o nome'),
  dia: yup.number().required('Digite o dia'),
  mes: yup.number().required('Digite o mês'),
  ano: yup.number().required('Digite o ano'),
  recorrencia: yup.boolean(),
  empresa: yup.string(),
});
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const Feriados: React.FC = () => {
  const { addToast } = useToast();
  const query = useQuery();
  const history = useHistory();

  const [feriado, setFeriado] = useState<Feriado[]>([]);
  const [FiltredFeriado, setFiltredFeriado] = useState<Feriado[]>([]);
  const [company, setCompany] = useState<Company[]>([]);
  const [feriadoEdit, setFeriadoEdit] = useState<Feriado>({
    id: '',
    name: '',
    dia: new Date().getDate(),
    mes: new Date().getMonth() + 1,
    ano: new Date().getFullYear(),
    recorrencia: false,
    empresa: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get<Feriado[]>('/feriado');
      const ordenado = response.data.sort(function (a, b) {
        return b.ano - a.ano;
      });
      setFeriado(ordenado);
      setFiltredFeriado(ordenado);

      const responseCompany = await api.get('/company');
      if (responseCompany.data) {
        setCompany(responseCompany.data);
      }
    }
    request();
  }, []);

  useEffect(() => {
    const id = query.get('id');
    if (id && feriado) {
      const feriadoFilter = feriado.find(feriadoItem => feriadoItem.id === id);

      if (feriadoFilter) {
        setValue('name', feriadoFilter.name);
        setValue('dia', feriadoFilter.dia);
        setValue('mes', feriadoFilter.mes);
        setValue('ano', feriadoFilter.ano);
        setValue('empresa', feriadoFilter.empresa);
        setFeriadoEdit(feriadoFilter);
      }
    }
  }, [query, feriado, setValue]);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      const dataPost = {
        ...feriadoEdit,
        name: data.name,
        dia: data.dia,
        mes: data.mes,
        ano: data.ano,
        recorrencia: data.recorrencia,
        empresa: data.empresa,
      };
      try {
        let response;
        dataPost.id !== ''
          ? (response = await api.put('feriado', dataPost))
          : (response = await api.post('feriado', dataPost));

        if (dataPost.id) {
          const feriadoUpdated = feriado;
          const indexferiado = feriadoUpdated.findIndex(
            feriadoItem => feriadoItem.id === dataPost.id,
          );
          feriadoUpdated[indexferiado] = response.data;
          setFiltredFeriado([...feriadoUpdated]);
        } else {
          setFiltredFeriado([...FiltredFeriado, response.data]);
        }

        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: 'Feriado foi salvo com sucesso',
        });
      } catch (error: any) {
        const response = error.response.data;
        if (response.status !== 200) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar feriado',
            description: response.message,
          });
        }
      }
    },
    [addToast, feriadoEdit, FiltredFeriado, feriado],
  );

  const handleFindFeriado = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      const filterips = feriado.filter(feriadoItem =>
        feriadoItem.name
          .toLowerCase()
          .includes(value.target.value.toLowerCase()),
      );
      setFiltredFeriado(filterips);
    },
    [feriado],
  );

  const handleCreateOrEdit = useCallback(() => {
    history.replace({
      search: '',
    });

    setFeriadoEdit({
      id: '',
      name: '',
      dia: new Date().getDate(),
      mes: new Date().getMonth() + 1,
      ano: new Date().getFullYear(),
      recorrencia: false,
      empresa: '',
    });
  }, [history]);

  const companyValues = useMemo(() => {
    return company.map<{ value: string; text: string }>(companyItem => {
      return {
        value: companyItem.id || '',
        text: companyItem.name,
      };
    });
  }, [company]);

  const handleFilterEmpresa = useCallback(
    event => {
      const feriadosFilter = feriado.filter(
        item => item.company?.id === event.target.value,
      );
      setFiltredFeriado(feriadosFilter);
    },
    [feriado],
  );

  const deleteHandle = useCallback(
    async id => {
      try {
        await api.delete(`/feriado/delete/${id}`);

        setFiltredFeriado(FiltredFeriado.filter(item => item.id !== id));
        setFeriado(feriado.filter(item => item.id !== id));

        addToast({
          type: 'success',
          title: 'Feriado excluido',
          description: 'Feriado excluido com sucesso :)',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Feriado',
          description: 'Falha ao excluir feriado :(',
        });
      }
    },
    [feriado, FiltredFeriado, addToast],
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">Feriado</div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex row">
              <Input
                erroMessage={errors.name?.message}
                error={!!errors.name?.message}
                placeholder="Nome feriado"
                label="Nome"
                defaultValue={feriadoEdit.name}
                reference={register('name')}
                name="name"
              />
              <Dropdown
                data={companyValues}
                label="Site"
                reference={register('empresa')}
                defaultValue={feriadoEdit.empresa}
              />
            </div>
            <div className="flex row">
              <Input
                erroMessage={errors.dia?.message}
                error={!!errors.dia?.message}
                placeholder="dia"
                label="Dia"
                max={31}
                min={1}
                type="number"
                defaultValue={feriadoEdit.dia}
                reference={register('dia')}
                name="dia"
              />
              <Input
                erroMessage={errors.mes?.message}
                error={!!errors.mes?.message}
                label="Mês"
                placeholder="mes"
                type="number"
                max={12}
                min={1}
                defaultValue={feriadoEdit.mes}
                reference={register('mes')}
                name="mes"
              />
              <Input
                erroMessage={errors.ano?.message}
                error={!!errors.ano?.message}
                label="Ano"
                placeholder="ano"
                type="number"
                defaultValue={feriadoEdit.ano}
                reference={register('ano')}
                name="ano"
              />
            </div>
            <div className="flex row">
              <div className="align-bottom">
                <label className="container">
                  <input
                    {...register('recorrencia')}
                    type="checkbox"
                    defaultChecked={feriadoEdit.recorrencia}
                    name="recorrencia"
                  />
                  Feriado ocorre no mesmo dia
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="flex">
              <Button style={{ width: 60, marginLeft: 'auto' }} type="submit">
                <FiSave size={25} color="#fff" />
              </Button>
            </div>
          </form>

          <div className="flex justify-end" />

          <PanelIps>
            <ActionContainer>
              <SearchBox>
                <Dropdown data={companyValues} onChange={handleFilterEmpresa} />
                <Input
                  name="search"
                  placeholder="Buscar por nome"
                  error={false}
                  onChange={handleFindFeriado}
                  style={{ width: 'auto' }}
                />
              </SearchBox>
              <Button
                onClick={() => handleCreateOrEdit()}
                style={{ width: 60, marginTop: 0 }}
              >
                <FiPlus size={25} color="#fff" />
              </Button>
            </ActionContainer>
            <div className="header">Feriados cadastrados</div>
            <FeriadoHeader>
              <div>Nome</div>
              <div>empresa</div>
              <div>Dia</div>
              <div>Mês</div>
              <div>Ano</div>
              <div>Recorrencia</div>
              <div />
            </FeriadoHeader>
            <BodyListFeriados>
              <div className="body">
                {FiltredFeriado &&
                  FiltredFeriado.map(FeriadoItem => {
                    return (
                      <FeriadosItem key={FeriadoItem.id}>
                        <div>{FeriadoItem.name}</div>
                        <div>{FeriadoItem.company?.name}</div>
                        <div>{FeriadoItem.dia}</div>
                        <div>{FeriadoItem.mes}</div>
                        <div>{FeriadoItem.ano}</div>
                        <div>{FeriadoItem.recorrencia ? 'Sim' : 'Não'}</div>
                        <div>
                          <Link to={`/Feriados?id=${FeriadoItem.id}`}>
                            <FiEdit size={15} color="#fff" />
                          </Link>
                          <Link
                            to="/Feriados"
                            onClick={() => {
                              deleteHandle(FeriadoItem.id);
                            }}
                          >
                            <FiTrash size={15} color="#fff" />
                          </Link>
                        </div>
                      </FeriadosItem>
                    );
                  })}
              </div>
            </BodyListFeriados>
          </PanelIps>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Feriados;
