/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import 'react-day-picker/lib/style.css';
import { FiEdit, FiPlus, FiSave, FiUser } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  Content,
  ActionContainer,
  SearchBox,
  IpItem,
  FormContainer,
  IpHeader,
  BodyListIps,
  PanelIps,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';
import Dropdown from '../../components/Dropdown';
import { Header, HeaderContent } from '../Feriados/styles';

interface Employee {
  id: string;
  name: string;
  email: string;
  document: string;
  phone?: string;
  zip?: string;
  street?: string;
  district?: string;
  city?: string;
  admissionDate: string | number;
  pis?: string;
  hours: string;
  interval: string;
  company: { id: string };
  company_id?: string;
  group_id?: string;
  group: { id: string };
  user: string;
  enabled: boolean;
  cargaHoraria_id?: string;
  coordenador_id?: string;
  mobile: boolean;
  codigo: number;
}
const schema = yup.object().shape({
  employee_id: yup.string().required('Selecione funcionario'),
  start: yup.date().required('informa data inicio'),
  end: yup.date().required('informa data fim'),
  codigo: yup.string(),
  substitute: yup.string(),
});
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const Atestado: React.FC = () => {
  const { addToast } = useToast();
  const query = useQuery();
  const history = useHistory();

  const [employees, setEmployees] = useState<Employee[]>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    debugger;
    async function request(): Promise<void> {
      const response = await api.get('/employee');
      setEmployees(response.data);
    }
    request();
  }, []);

  const employeeValues = useMemo(() => {
    const employeesFilter = employees.filter(
      employee => employee.enabled === true,
    );

    return employeesFilter.map<{ value: string; text: string }>(
      employeeItem => {
        return {
          value: employeeItem.id,
          text: employeeItem.name,
        };
      },
    );
  }, [employees]);

  const employeeCode = useMemo(() => {
    const employeesFilter = employees.filter(
      employee => employee.enabled === true,
    );

    return employeesFilter.map<{ value: string; text: string }>(
      employeeItem => {
        return {
          value: employeeItem.codigo.toString(),
          text: employeeItem.name,
        };
      },
    );
  }, [employees]);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      try {
        const dataPost = data;
        if (data.substitute === '') {
          delete dataPost.substitute;
        }
        const response = await api.post('register/atestado', dataPost);

        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: response.data.message,
        });
      } catch (error) {
        const response = error.response.data;
        if (response.status !== 200) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: response.message,
          });
        }
      }
    },
    [addToast],
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">Cadastro de Atestado</div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex row">
              <Dropdown
                data={employeeValues}
                label="Funcionario"
                reference={register('employee_id')}
                erroMessage={errors.employee_id?.message}
                error={!!errors.employee_id?.message}
              />
              <Input
                erroMessage={errors.start?.message}
                error={!!errors.start?.message}
                reference={register('start')}
                label="Inicio"
                name="start"
                type="date"
              />
              <Input
                erroMessage={errors.end?.message}
                error={!!errors.end?.message}
                reference={register('end')}
                label="Fim"
                name="end"
                type="date"
              />
            </div>
            <div className="row">
              <Dropdown
                data={employeeCode}
                label="Funcionario Substituto"
                reference={register('substitute')}
                erroMessage={errors.substitute?.message}
                error={!!errors.substitute?.message}
              />
            </div>
            <div className="flex">
              <Button style={{ width: 60, marginLeft: 'auto' }} type="submit">
                <FiSave size={25} color="#fff" />
              </Button>
            </div>
          </form>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Atestado;
