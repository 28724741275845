import React, {
  InputHTMLAttributes,
  useRef,
  useState,
  useCallback,
} from 'react';
import InputMask from 'react-input-mask';
import { UseFormRegisterReturn } from 'react-hook-form';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error, Field } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name?: string;
  error?: boolean;
  reference?: UseFormRegisterReturn;
  erroMessage?: string;
  mask?: string;
  label?: string;
  containerStyle?: React.CSSProperties;
  autoCompleteText?: 'on' | 'off';
  icon?: React.ComponentType<IconBaseProps>;
}

const TextArea: React.FC<InputProps> = ({
  containerStyle = {},
  error,
  reference,
  erroMessage,
  autoCompleteText,
  mask,

  label,
  icon: Icon,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Field>
      {label && <div className="label p-2">{label}</div>}
      <Container
        style={containerStyle}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        data-testid="input-container"
      >
        {Icon && <Icon size={20} />}
        <textarea {...rest} {...reference} />

        {error && (
          <Error title={erroMessage}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </Field>
  );
};

export default TextArea;
