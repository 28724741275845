/* eslint-disable no-restricted-globals */
/* eslint-disable no-debugger */
import React, { useCallback, useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import 'leaflet/dist/leaflet.css';
import format from 'date-fns/format';
import { ptBR } from 'date-fns/locale';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { CalendarContainer } from 'react-datepicker';
import {
  Container,
  Content,
  PainelRegistro,
  RegistersDay,
  PainelPendencia,
} from './styles';

import Menu from '../../components/Menu';
import SideBar from '../../components/SideBar';
import api, { microsoftApi } from '../../services/api';

import tierhIcon from '../../utils/mapIcon';
import { useToast } from '../../hooks/toast';
import { useDate } from '../../hooks/date';
import Substituicao from '../../components/Substituicao';
import Button from '../../components/Button';
import { Header, HeaderContent } from '../Feriados/styles';

interface PostData {
  longitude: number;
  latitude: number;
  description?: string;
  type?: number;
}

export interface RegisterItem {
  date: Date;
  id: string;
  substituition_id: string;
  valid: boolean;
  approved_by_id: string;
}

interface RegesterReturn {
  registers: Array<RegisterItem>;
  extraHour: boolean;
  ListDaysError?: Array<{ day: Date; registers: RegisterItem[] }>;
}

const schema = yup.object().shape({
  justificativa: yup.string(),
});

const Dashboard: React.FC = () => {
  const [currentLocal, setCurrentLocal] = useState<{ locality: string }>({
    locality: '',
  });
  const [geolocaleAtual, setGeolocaleAtual] = useState<{
    latitude: number;
    longitude: number;
  }>({ longitude: 0, latitude: 0 });
  const [date, setDate] = useState<Date>(new Date());
  const [registersToday, setRegistersToday] = useState<RegesterReturn>();
  const [registerValid, setRegisterValid] = useState<RegisterItem[]>();
  const [dataSelecionada, setDataSelecionada] = useState<Date | undefined>();
  const [idSelecionada, setIdSelecionada] = useState<string>();
  const [showSubstituicao, setShowSubstituicao] = useState<boolean>(false);
  const { addToast } = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  if ('geolocation' in navigator) {
    /* geolocation is available */
  } else {
    alert(
      "I'm sorry, but geolocation services are not supported by your browser.",
    );
  }

  const FindGeolocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(async position => {
      if (geolocaleAtual.latitude !== position.coords.latitude) {
        setGeolocaleAtual({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      }
    });
  }, [geolocaleAtual]);

  useEffect(() => {
    async function AtualizarLocal(): Promise<void> {
      if (geolocaleAtual.latitude) {
        const local = await microsoftApi.get(
          `${geolocaleAtual.latitude}, ${geolocaleAtual.longitude}`,
        );

        setCurrentLocal(local.data.resourceSets[0].resources[0].address);
      }
    }
    AtualizarLocal();
  }, [geolocaleAtual]);

  useEffect(() => {
    FindGeolocation();
  }, []);

  const { time, dateAtual } = useDate(date);
  useEffect(() => {
    async function Request(): Promise<void> {
      const [dateServer, registrosDay] = await Promise.all([
        api.get('register/hour'),
        api.get<RegesterReturn>('register/'),
      ]);
      setDate(new Date(dateServer.data.data));
      const registersValid = registrosDay.data.registers.filter(
        registeraval =>
          (registeraval.approved_by_id && registeraval.valid === true) ||
          (((registeraval.approved_by_id === null &&
            (registeraval.valid === true || registeraval.valid === false)) ||
            (registeraval.approved_by_id !== null &&
              registeraval.valid === true)) &&
            registeraval.substituition_id === null &&
            (registeraval.valid === true || registeraval.valid === false)),
      );

      setRegisterValid(registersValid);
      setRegistersToday(registrosDay.data);
    }

    Request();
  }, [showSubstituicao]);

  // useInterval(() => {
  //   setDate(new Date(date.getTime() + 1000));
  // }, 1000);

  const RegistrosNaoValidados = useCallback(
    id => {
      if (!registersToday) {
        return '';
      }
      const substituido = registersToday.registers.filter(
        item => item.substituition_id === id,
      );
      return substituido.map(item => {
        return (
          <span className="substituir">
            {format(new Date(item.date), 'HH:mm')}
          </span>
        );
      });
    },
    [registersToday],
  );

  const RegistrosEmValidacao = useCallback(
    registerValidate => {
      if (!registersToday || registerValidate.approved_by_id) {
        return '';
      }

      return (
        <span className="substituir" style={{ color: '#ff9000' }}>
          {format(new Date(registerValidate.date), 'HH:mm')}
        </span>
      );
    },
    [registersToday],
  );

  const onSubmit = useCallback(
    async (data, event) => {
      const buttonRegister = event.target[1];
      try {
        if (!registersToday) {
          return;
        }
        if (!registerValid) {
          return;
        }
        buttonRegister.disabled = true;
        FindGeolocation();
        let submitData: PostData = {
          latitude: geolocaleAtual.latitude,
          longitude: geolocaleAtual.longitude,
        };

        if (registersToday?.extraHour && data.justificativa) {
          submitData = {
            ...submitData,
            description: data.justificativa,
            type: 1,
          };
        }
        const registerHour = await api.post('register/', submitData);

        const registrosDay = await api.get<RegesterReturn>('register/');
        const registersValid = registrosDay.data.registers.filter(
          registeraval =>
            registeraval.substituition_id === null &&
            (registeraval.valid === true || registeraval.valid === false),
        );
        setRegisterValid(registersValid);
        setRegistersToday(registrosDay.data);

        setTimeout(
          button => {
            const enableButton = button;
            enableButton.disabled = false;
          },
          1000 * 20,
          buttonRegister,
        );
        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: `Registro efetuado ${format(
            new Date(registerHour.data.date),
            'HH:mm',
          )}`,
        });
      } catch (error: any) {
        buttonRegister.disabled = false;
        const response = error.response.data;
        addToast({
          type: 'error',
          title: 'Erro ao registrar',
          description: response.message,
        });
        const registrosDay = await api.get<RegesterReturn>('register/');
        setRegistersToday(registrosDay.data);
      }
    },
    [geolocaleAtual, addToast, registersToday, FindGeolocation],
  );

  if (geolocaleAtual.latitude === 0) {
    return <div className="center">Carregando...</div>;
  }

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />
      <Content>
        <MapContainer
          bounds={[
            [geolocaleAtual.latitude + 0.00065, geolocaleAtual.longitude],
          ]}
          style={{ height: '100%', width: '100%' }}
          zoom={15}
          scrollWheelZoom={false}
        >
          <TileLayer
            url={`https://api.mapbox.com/styles/v1/mapbox/light-v10/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`}
          />
          <Marker
            interactive={false}
            icon={tierhIcon}
            position={[geolocaleAtual.latitude, geolocaleAtual.longitude]}
          >
            <Popup>
              Voce esta. <br /> aqui.
            </Popup>
          </Marker>
          <div className="panels">
            <PainelRegistro extraHour={registersToday?.extraHour}>
              <div>
                <div className="header">Marcação</div>
                <div>
                  <h1>{time}</h1>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="justificativa">
                    <div>
                      Você esta fazendo hora extra, você deve informar uma
                      justificativa
                    </div>
                    <div>
                      <textarea {...register('justificativa')} rows={7} />
                    </div>
                  </div>

                  <div className="gray-color">
                    Fuso horaio em: {currentLocal.locality || ''} <br />
                    {format(dateAtual, 'PPPppp', { locale: ptBR })}
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={
                        (registersToday &&
                          registersToday.ListDaysError &&
                          registersToday.ListDaysError.length > 0) ||
                        false
                      }
                    >
                      Confirmar
                    </button>
                  </div>
                </form>
              </div>
            </PainelRegistro>
            <PainelPendencia
              show={
                (registersToday &&
                  registersToday.ListDaysError &&
                  registersToday.ListDaysError.length > 0) ||
                false
              }
            >
              <div>
                {registersToday &&
                  registersToday.ListDaysError &&
                  registersToday.ListDaysError.length > 0 && (
                    <>
                      <div>
                        <h3 className="alert">
                          Atenção você tem pendencia(s) na(s) data(s) e deve
                          resolver antes de continuar
                        </h3>
                      </div>
                      <div className="datas">
                        {registersToday.ListDaysError.map(itemErro => {
                          return (
                            <>
                              <span>
                                {format(new Date(itemErro.day), 'dd-MM-yyyy')}{' '}
                                Registros do dia as:{' '}
                                {itemErro.registers
                                  .map(registerItem =>
                                    format(
                                      new Date(registerItem.date),
                                      'HH:mm',
                                    ),
                                  )
                                  .join(', ')}
                              </span>
                            </>
                          );
                        })}
                      </div>
                      <Button
                        className="botaoNovoRegistro"
                        onClick={() => {
                          setIdSelecionada('');
                          setDataSelecionada(undefined);
                          setShowSubstituicao(!showSubstituicao);
                        }}
                      >
                        Submissão Registro
                      </Button>
                    </>
                  )}
              </div>
            </PainelPendencia>
          </div>
          <RegistersDay>
            <div>
              <div
                className="flex space-between header"
                style={{
                  backgroundColor: '#fff',
                  color: '#161616',
                  paddingTop: 0,
                }}
              >
                Entrada
              </div>
              <div
                className="flex space-between header"
                style={{
                  backgroundColor: '#fff',
                  color: '#161616',
                  paddingTop: 0,
                }}
              >
                Saida
              </div>
              <div className="flex space-between" style={{ paddingTop: 0 }}>
                <div>Validado</div>
                <div>Em Avaliação</div>
              </div>
              <div className="flex space-between" style={{ paddingTop: 0 }}>
                <div>Validado</div>
                <div>Em Avaliação</div>
              </div>
              {registerValid &&
                registerValid.map((registerItem, index) => {
                  const saida = index % 2;

                  return (
                    <div className="flex space-between">
                      <Link
                        to={`#${registerItem.id}`}
                        onClick={() => {
                          setIdSelecionada(registerItem.id);
                          setDataSelecionada(registerItem.date);
                          setShowSubstituicao(!showSubstituicao);
                        }}
                      >
                        {registerItem.valid &&
                          format(new Date(registerItem.date), 'HH:mm')}{' '}
                        <span>
                          {saida
                            ? `S${((index + 1) / 2).toFixed()}`
                            : `E${((index + 1) / 2).toFixed()}`}
                        </span>
                      </Link>
                      <div style={{ padding: 0 }}>
                        {RegistrosNaoValidados(registerItem.id)}
                        {!registerItem.valid &&
                          RegistrosEmValidacao(registerItem)}
                      </div>
                    </div>
                  );
                })}
            </div>
          </RegistersDay>
        </MapContainer>
      </Content>
      <Substituicao
        closeFunction={() => {
          setShowSubstituicao(!showSubstituicao);
        }}
        show={showSubstituicao}
        dataHora={dataSelecionada}
        id={idSelecionada}
        longitude={geolocaleAtual.longitude}
        latitude={geolocaleAtual.latitude}
      />
    </Container>
  );
};

export default Dashboard;
