import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  position: absolute;
  max-width: 100vw;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    a {
      text-decoration: none;
      color: #ff9000;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Content = styled.main`
  position: relative;
  height: calc(100vh - 60px);
  width: Calc(100% - 240px);
  padding: 10px;
  margin-left: 240px;
  display: flex;
  flex-direction: column;

  .form {
    padding-right: 10px;
  }
`;

export const PanelIps = styled.div`
  transition: left ease-in-out 1s;
  position: relative;
  width: 100%;
  margin: 20px 0;
  left: 0;

  .header {
    padding: 15px;
    font-size: 22px;
    font-weight: 600;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  a {
    background-color: #005ead;
    padding: 10px 10px;
    margin-top: 25px;
    border-radius: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: #0070ce;
    }
  }
`;

export const SearchBox = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const BodyListIps = styled.div`
  margin-top: 10px;
`;

export const IpItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0 10px;
  justify-content: space-between;

  &:nth-of-type(odd) {
    background: var(--alternateColorRow);
  }

  &:nth-of-type(even) {
    background: var(--alternateColorRowEven);
  }

  & + div {
    border-top: solid 1px #e1e1e1;
    display: flex;
    justify-content: center;
  }

  button {
    background-color: transparent;
    border: none;
  }

  div {
    display: flex;
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:first-child {
      flex: 1;
    }
    &:last-child {
      justify-content: flex-end;
      flex-direction: row;
      align-items: center;
    }
    a {
      border: solid 1px #e1e1e1;
      border-radius: 5px;
      padding: 5px;
      width: 28px;
      display: flex;

      color: #161616;
      transition: background-color 0.2s;

      &:hover {
        background-color: #d1d1d1;
      }
    }
  }
`;

export const IpHeader = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0 10px;
  justify-content: space-between;
  color: var(--labelColor);

  div {
    display: flex;
    min-width: 100px;

    &:first-child {
      flex: 1;
    }
    &:last-child {
      justify-content: flex-end;
    }
  }
`;

export const FormContainer = styled.div`
  padding-right: 15px;
  max-width: 100%;
  margin: 15px 15px;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: var(--panelColor);
  .header {
    border-bottom: solid 1px var(--borderInPanel);
    font-size: 1.6rem;
    margin: 0 -15px;
    padding: 10px 25px;
  }
  form {
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 2px;
    }
    button {
      margin-left: auto;
    }
  }
`;
