/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';

import 'react-day-picker/lib/style.css';

import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { Container, Content } from './styles';

import api from '../../services/api';

import Menu from '../../components/Menu';
import SideBar from '../../components/SideBar';

import { Header, HeaderContent } from '../Feriados/styles';
import AlertDialogPapeleta from '../../components/Dialog';

interface Employee {
  name: string;
}

interface Registers {
  date: Date;
  id: string;
  valid: boolean;
}

const Papeleta: React.FC = () => {
  const { mesAno } = useParams<{ mesAno: string }>();

  const [registers, setRegisters] = useState<Registers[]>([]);

  const ensureArrayLength = (
    arr: Array<string>,
    length: number,
    fillValue: string,
  ): Array<string> => {
    while (arr.length < length) {
      arr.push(fillValue);
    }
    return arr;
  };

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.post('register/month', {
        month: mesAno.split('-')[0],
        year: mesAno.split('-')[1],
      });
      debugger;
      setRegisters(response.data.registers);
    }
    request();
  }, [mesAno]);
  const filter = mesAno.split('-');
  const daysInMonth = new Date(
    parseInt(filter[1]),
    parseInt(filter[0]),
    0,
  ).getDate();
  const monthDays = [];
  if (registers) {
    for (let day = 1; day <= daysInMonth; day += 1) {
      const formattedDate = `${day.toString().padStart(2, '0')}/${filter[0]
        .toString()
        .padStart(2, '0')}/${filter[1]}`;
      const dayHours = registers
        .filter(
          registerValue =>
            new Date(registerValue.date).getDate() === day &&
            registerValue.valid,
        )
        .map(hour => format(new Date(hour.date), 'HH:mm'));
      console.log(dayHours.length);
      monthDays.push({
        date: formattedDate,
        hours: ensureArrayLength(dayHours, 8, '-'),
      });
    }
  } else {
    return <div>...Carregando</div>;
  }
  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            color: '#161616',
            fontSize: 11,
          }}
        >
          <div
            style={{
              maxWidth: 700,
              textJustify: 'auto',
              backgroundColor: '#f1f1f1',
            }}
          >
            <table
              style={{
                fontSize: 11,
                borderColor: '#161616',
                maxWidth: 700,
                color: '#161616',
                backgroundColor: '#f1f1f1',
              }}
            >
              <thead>
                <tr>
                  <td>Data</td>
                  <td>Entrada 1</td>
                  <td>Saida 1</td>
                  <td>Entrada 2</td>
                  <td>Saida 2</td>
                  <td>Entrada 3</td>
                  <td>Saida 3</td>
                  <td>Entrada 4</td>
                  <td>Saida 4</td>
                </tr>
              </thead>
              <tbody>
                {monthDays.map(day => (
                  <tr key={day.date} style={{ backgroundColor: '#f1f1f1' }}>
                    <td style={{ padding: 4, textAlign: 'center' }}>
                      {day.date}
                    </td>
                    {/* <td>{day.hours.length}</td> */}
                    {day.hours.map((hour, index) => (
                      <td
                        key={`${day.date}-${hour}-${index}`}
                        style={{ padding: 4, textAlign: 'center' }}
                      >
                        {hour}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <p
              style={{
                padding: 8,
                textAlign: 'justify',
                textJustify: 'inter-word',
              }}
            >
              Por favor, revise todas as suas datas e respectivos horários de
              entrada e saída apresentados no seu relatório. Certifique-se de
              que todas as informações estão corretas e condizem com os horários
              efetivamente trabalhados. Caso identifique alguma discrepância ou
              erro, entre em contato imediatamente com o setor de Recursos
              Humanos para as devidas correções antes de prosseguir com a
              impressão.
            </p>
            <br />

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ padding: 8 }}>
                Clique em &quot;Confirmar&quot; para prosseguir
              </p>
              <AlertDialogPapeleta
                titulo="Confirmar"
                filtro={{
                  month: parseInt(mesAno.split('-')[0]),
                  year: parseInt(mesAno.split('-')[1]),
                }}
              />
            </div>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default Papeleta;
