import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import Employee from '../pages/Employee';
import Horas from '../pages/Horas';
import Submissions from '../pages/Substituicao';
import Ips from '../pages/ip';
import Companies from '../pages/company';
import Historico from '../pages/Historico';
import HistoricoSubistituicao from '../pages/HistoricoSubistituicao';
import Feriados from '../pages/Feriados';
import Ferias from '../pages/ferias';
import Report from '../pages/relatorio';
import HistoricoAdmin from '../pages/HistoricoAdmin';
import Ocorrencias from '../pages/Ocorrencias';
import CreateOrEdit from '../pages/Employee/CreateOrEdit';
import Download from '../pages/download';
import Atestado from '../pages/atestado';
import Status from '../pages/StatusDia';
import Papeleta from '../pages/Historico/papeleta';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />
    <Route path="/forgot-password" component={ForgotPassword} />
    <Route path="/reset-password" component={ResetPassword} />

    <Route path="/profile" component={Profile} isPrivate />
    <Route path="/dashboard" component={Dashboard} isPrivate />
    <Route path="/history/:mesAno" component={Papeleta} isPrivate />
    <Route path="/history" component={Historico} isPrivate />

    <Route path="/Submission" component={HistoricoSubistituicao} isPrivate />
    <Route
      path="/employee/createOrEdit"
      component={CreateOrEdit}
      isPrivate
      isAdmin
    />
    <Route path="/employee" component={Employee} exact isPrivate isAdmin />
    <Route path="/hours" component={Horas} isPrivate isAdmin />
    <Route path="/ip" component={Ips} isPrivate isAdmin />
    <Route path="/feriados" component={Feriados} isPrivate isAdmin />
    <Route path="/company" component={Companies} isPrivate isAdmin />
    <Route path="/Ferias" component={Ferias} isPrivate isAdmin />
    <Route path="/submissions" component={Submissions} isPrivate isCoord />
    <Route path="/report" component={Report} isPrivate />
    <Route path="/ocorrencias" component={Ocorrencias} isPrivate />
    <Route path="/register/download" component={Download} isAdmin isPrivate />
    <Route path="/register/atestado" component={Atestado} isAdmin isPrivate />
    <Route path="/register/status" component={Status} isAdmin isPrivate />
    <Route
      path="/historicoAdmin"
      component={HistoricoAdmin}
      isPrivate
      isAdmin
    />
  </Switch>
);

export default Routes;
