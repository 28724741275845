import React, { useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { Container, Content } from '../Dashboard/styles';
import SideBar from '../../components/SideBar';
import { Header, HeaderContent } from '../Feriados/styles';
import Menu from '../../components/Menu';
import api from '../../services/api';
import { RegisterItem } from '../Dashboard';
import { Employee } from '../Employee/CreateOrEdit';

interface StatusRegister {
  registers?: RegisterItem[];
  extraHour: boolean;
  ListDaysError: Array<{ day: Date; registers: RegisterItem[] }>;
  totalMinutosTrabalhados?: number;
  minutosCargaHoraria?: number;
  employee: Employee;
}

interface StatusType {
  intervalo: Array<StatusRegister>;
  trabalhando: Array<StatusRegister>;
  falta: Array<StatusRegister>;
  concluido: Array<StatusRegister>;
  extra: Array<StatusRegister>;
}

const Status: React.FC = () => {
  const [statusAtual, setStatusAtual] = useState<StatusType>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const request = async (): Promise<void> => {
      const response = await api.get<StatusType>('/register/status');
      setStatusAtual(response.data);
      setLoading(false);
    };
    request();
  }, []);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        {loading ? (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            Carregando...
          </div>
        ) : (
          <Accordion.Root type="multiple">
            <Accordion.Item value="Intervalo">
              <Accordion.Header>
                <Accordion.Trigger>Intervalo</Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="flex">
                  {statusAtual &&
                    statusAtual.intervalo.map(item => {
                      return (
                        <div
                          style={{
                            padding: 10,
                            background: '#30333c',
                            margin: 5,
                            borderRadius: 5,
                          }}
                        >
                          {item.employee.name}
                        </div>
                      );
                    })}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="Trabalhando">
              <Accordion.Header>
                <Accordion.Trigger>Trabalhando</Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="flex">
                  {statusAtual &&
                    statusAtual.trabalhando.map(item => {
                      return (
                        <div
                          style={{
                            padding: 10,
                            background: '#30333c',
                            margin: 5,
                            borderRadius: 5,
                          }}
                        >
                          {item.employee.name}
                        </div>
                      );
                    })}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="Acabou">
              <Accordion.Header>
                <Accordion.Trigger>
                  Acabou o trabalho por hoje
                </Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="flex">
                  {statusAtual &&
                    statusAtual.concluido.map(item => {
                      return (
                        <div
                          style={{
                            padding: 10,
                            background: '#30333c',
                            margin: 5,
                            borderRadius: 5,
                          }}
                        >
                          {item.employee.name}
                        </div>
                      );
                    })}
                </div>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="Faltou">
              <Accordion.Header>
                <Accordion.Trigger>Não entrou ainda</Accordion.Trigger>
              </Accordion.Header>
              <Accordion.Content>
                <div className="flex">
                  {statusAtual &&
                    statusAtual.falta.map(item => {
                      return (
                        <div
                          style={{
                            padding: 10,
                            background: '#30333c',
                            margin: 5,
                            borderRadius: 5,
                          }}
                        >
                          {item.employee.name}
                        </div>
                      );
                    })}
                </div>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        )}
      </Content>
    </Container>
  );
};

export default Status;
