import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999999999;

  overflow: hidden;
`;
