import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  existLabel?: boolean;
}

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Container = styled.div<ContainerProps>`
  align-items: flex-start;
  padding-top: 16px;
  background: #232129;
  border-radius: 10px;
  padding: 16px;
  width: 100%;

  border: 2px solid #232129;
  color: #666360;

  display: flex;
  align-items: center;

  ${props =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      color: #ff9000;
      border-color: #ff9000;
    `}

    ${props =>
    props.existLabel &&
    css`
      height: 56px;
    `}

  ${props =>
    props.isFilled &&
    css`
      color: #ff9000;
    `}
    select {
    flex: 1;
    background: transparent;
    border: 0;
    font-size: 16px;
    color: #fff;
    outline-width: 0px;
    &::placeholder {
      color: #666360;
    }
    option {
      color: #000;
    }
  }
`;
export const Label = styled.div`
  font-weight: 400 !important;
  font-size: 10px;
  padding-bottom: 8px;
  padding-left: 11px;
`;

export const InputContainer = styled.div<ContainerProps>`
  border-radius: 8px;
  padding: 16px;

  border: 1px solid #989fdb;
  color: #666360;
  display: flex;
  align-items: center;
  & + div {
    margin-top: 8px;
  }
  ${props =>
    props.isErrored &&
    css`
      border-color: #ff377f;
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: #383e71;
    `}

  select {
    flex: 1;
    background: transparent;
    border: 0;
    font-size: 16px;
    outline-width: 0px;
    &::placeholder {
      color: #666360;
    }

    option {
      font-size: 16px;
    }
  }
  svg {
    margin-right: 16px;
  }
`;
export const ErroMessage = styled.div`
  color: #ff377f;
  padding-left: 17px;
  font-size: 10px;
`;

export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;
  flex: none !important;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;

    &::before {
      border-color: #c53030 transparent;
    }
  }
`;
