/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import 'react-day-picker/lib/style.css';
import { FiEdit, FiPlus, FiSave, FiTrash, FiUser } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  Content,
  ActionContainer,
  SearchBox,
  IpItem,
  FormContainer,
  IpHeader,
  BodyListIps,
  PanelIps,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';
import { Header, HeaderContent } from '../Feriados/styles';

interface Ip {
  id: string;
  name: string;
  ip: string;
}

const schema = yup.object().shape({
  name: yup.string().required('Digite o nome'),
  ip: yup.string().required('Digite o ip'),
});
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const Ips: React.FC = () => {
  const { addToast } = useToast();
  const query = useQuery();
  const history = useHistory();

  const [ips, setIps] = useState<Ip[]>([]);
  const [FiltredIps, setFiltredIps] = useState<Ip[]>([]);
  const [ipsEdit, setIpsEdit] = useState<Ip>({
    id: '',
    name: '',
    ip: '',
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get('ip');
      setIps(response.data);
      setFiltredIps(response.data);
    }
    request();
  }, []);

  useEffect(() => {
    const id = query.get('id');
    if (id && ips) {
      const ipFilter = ips.find(ipItem => ipItem.id === id);

      if (ipFilter) {
        setIpsEdit(ipFilter);
      }
    }
  }, [query, ips]);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      const dataPost = {
        ...ipsEdit,
        name: data.name,
        ip: data.ip,
      };
      try {
        let response;
        dataPost.id !== ''
          ? (response = await api.put('ip', dataPost))
          : (response = await api.post('ip', dataPost));

        if (dataPost.id) {
          const ipsUpdated = ips;
          const indexip = ipsUpdated.findIndex(
            horaItem => horaItem.id === dataPost.id,
          );
          ipsUpdated[indexip] = response.data;
          setFiltredIps([...ipsUpdated]);
        } else {
          setFiltredIps([...FiltredIps, response.data]);
        }

        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: 'Ip foi salvo com sucesso',
        });
      } catch (error: any) {
        const response = error.response.data;
        if (response.status !== 200) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar ip',
            description: response.message,
          });
        }
      }
    },
    [addToast, ipsEdit, FiltredIps, ips],
  );

  const handleFindIp = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      const filterips = ips.filter(ipItem =>
        ipItem.name.toLowerCase().includes(value.target.value.toLowerCase()),
      );
      setFiltredIps(filterips);
    },
    [ips],
  );

  const handleCreateOrEdit = useCallback(() => {
    history.replace({
      search: '',
    });

    setIpsEdit({
      id: '',
      name: '',
      ip: '',
    });
  }, [history]);

  const handleDelete = useCallback(
    async id => {
      try {
        await api.delete(`/ip/${id}`);

        setFiltredIps(FiltredIps.filter(item => item.id !== id));
        setIps(ips.filter(item => item.id !== id));

        addToast({
          type: 'success',
          title: 'IP excluido',
          description: 'IP excluido com sucesso :)',
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'IP',
          description: 'Falha ao excluir IP :(',
        });
      }
    },
    [ips, FiltredIps, addToast],
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">Cadastro de IP</div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex row">
              <Input
                erroMessage={errors.name?.message}
                error={!!errors.name?.message}
                placeholder="Nome"
                defaultValue={ipsEdit.name}
                reference={register('name')}
                name="name"
              />
              <Input
                erroMessage={errors.ip?.message}
                error={!!errors.ip?.message}
                placeholder="Ip ex: 192.1.1.1"
                defaultValue={ipsEdit.ip}
                reference={register('ip')}
                name="ip"
              />
            </div>
            <div className="flex">
              <Button style={{ width: 60, marginLeft: 'auto' }} type="submit">
                <FiSave size={25} color="#fff" />
              </Button>
            </div>
          </form>

          <PanelIps>
            <ActionContainer>
              <SearchBox>
                <Input
                  name="search"
                  placeholder="Buscar por nome"
                  error={false}
                  onChange={handleFindIp}
                  style={{ width: 'auto' }}
                />
              </SearchBox>
              <Button
                onClick={() => handleCreateOrEdit()}
                style={{ width: 60, marginTop: 0 }}
              >
                <FiPlus size={25} color="#fff" />
              </Button>
            </ActionContainer>
            <div className="header">IPs Cadastrados</div>
            <IpHeader>
              <div>Nome</div>
              <div>IP</div>
              <div>Ação</div>
            </IpHeader>
            <BodyListIps>
              <div className="body">
                {FiltredIps &&
                  FiltredIps.map(ipItem => {
                    return (
                      <IpItem key={ipItem.id}>
                        <div>{ipItem.name}</div>
                        <div>{ipItem.ip}</div>

                        <div>
                          <Link to={`/ip?id=${ipItem.id}`}>
                            <FiEdit size={15} color="#fff" />
                          </Link>
                          <Link
                            to="/ip"
                            onClick={() => {
                              handleDelete(ipItem.id);
                            }}
                          >
                            <FiTrash size={15} color="#fff" />
                          </Link>
                        </div>
                      </IpItem>
                    );
                  })}
              </div>
            </BodyListIps>
          </PanelIps>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Ips;
