import React, { useCallback } from 'react';
import { FiLock } from 'react-icons/fi';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useToast } from '../../hooks/toast';

import logoImg from '../../assets/logo.png';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AnimationContainer, Background } from './styles';
import api from '../../services/api';

interface ResetPasswordFormData {
  password: string;
  passwordConfirmation: string;
}

const schema = Yup.object().shape({
  password: Yup.string().required('Senha obrigatória'),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Confirmação incorreta',
  ),
});

const SignIn: React.FC = () => {
  const { addToast } = useToast();

  const history = useHistory();
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { password, passwordConfirmation } = data;
        const token = location.search.replace('?token=', '');

        if (!token) {
          throw new Error();
        }

        await api.post('/password/reset', {
          password,
          password_confirmation: passwordConfirmation,
          token,
        });

        history.push('/');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao resetar senha',
          description: 'Ocorreu um erro ao resetar sua senha, tente novamente.',
        });
      }
    },
    [addToast, history, location.search],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Tierh" />

          <form onSubmit={handleSubmit(onSubmit)}>
            <h1>Resetar senha</h1>

            <Input
              error={!!errors.password?.message}
              erroMessage={errors.password?.message}
              reference={register('password')}
              icon={FiLock}
              type="password"
              placeholder="Nova senha"
              containerStyle={{ marginBottom: 10 }}
            />

            <Input
              reference={register('passwordConfirmation')}
              error={!!errors.passwordConfirmation?.message}
              erroMessage={errors.passwordConfirmation?.message}
              name="passwordConfirmation"
              icon={FiLock}
              type="password"
              placeholder="Confirmação da senha"
            />

            <Button type="submit">Alterar senha</Button>
          </form>
        </AnimationContainer>
      </Content>

      <Background />
    </Container>
  );
};

export default SignIn;
