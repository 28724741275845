import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 58px;
  background-color: #fff;
  margin-right: 10px;
  a {
    text-decoration: none;
    color: #ff9000;
    padding: 0 5px;

    &:hover {
      opacity: 0.8;
    }
  }
  .iconeNotification {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 58px;
  }
  .notView {
    background-color: #f3f3f3;
  }
  .icon-button__badge {
    position: absolute;
    top: 10px;
    right: -5px;
    width: 15px;
    height: 15px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 10px;
  }
`;
