import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { Logo, Nav, Profile, Scroll, SidebarContainer } from './styles';

import logoImg from '../../assets/logo.png';

const SideBar: React.FC = () => {
  const { user, employee } = useAuth();
  const admin = employee.group.id === 'eee571c7-b7eb-4b13-a4e5-70aa1c4bf4ce';
  const coordenador =
    employee.group.id === '077d9a30-f134-431f-a552-570657640070';
  return (
    <SidebarContainer>
      <Logo>
        <img src={logoImg} alt="Tierh" />
      </Logo>
      <Scroll>
        <Nav>
          <Profile>
            <img src={user.avatar_url} alt={user.name} />
            <div>
              <Link to="/profile">
                <strong>{user.name}</strong>
              </Link>
            </div>
          </Profile>
          <ul>
            <li>
              <Link to="/dashboard">
                <strong>Registro de horário</strong>
              </Link>
            </li>
            <li>
              <Link to="/history">
                <strong>Histórico de registros</strong>
              </Link>
            </li>
            <li>
              <Link to="/Submission">
                <strong>Submissão</strong>
              </Link>
            </li>
            {admin && (
              <>
                <li className="header">Painel Administrativo</li>
                <li>
                  <Link to="/employee">
                    <strong>Funcionarios</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/hours">
                    <strong>Horários</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/company">
                    <strong>Site</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/ip">
                    <strong>IP</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/Submissions">
                    <strong>Avaliar Submissões</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/Ferias">
                    <strong>Férias</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/Feriados">
                    <strong>Feriados</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/historicoAdmin">
                    <strong>Consultar/Apagar registro</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/report">
                    <strong>Relatório Excel</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/ocorrencias">
                    <strong>Ocorrências</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/register/atestado">
                    <strong>Atestado</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/register/status">
                    <strong>Status Hoje</strong>
                  </Link>
                </li>
              </>
            )}
            {coordenador && (
              <>
                <li className="header">Painel Administrativo</li>
                <li>
                  <Link to="/Submissions">
                    <strong>Avaliar Submissões</strong>
                  </Link>
                </li>
                <li>
                  <Link to="/historicoAdmin">
                    <strong>Consultar/Apagar registro</strong>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </Nav>
      </Scroll>
    </SidebarContainer>
  );
};
export default SideBar;
