import React, {
  InputHTMLAttributes,
  useRef,
  useState,
  useCallback,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error, Field } from './styles';

interface SelectProps extends InputHTMLAttributes<HTMLSelectElement> {
  label?: string;
  error?: boolean;
  reference?: UseFormRegisterReturn;
  data?: { value: string; text: string }[];
  erroMessage?: string;
}

const Dropdown: React.FC<SelectProps> = ({
  label,
  error,
  erroMessage,
  reference,
  data,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Field>
      {label && <div className="label p-2">{label}</div>}
      <Container
        existLabel={!!label}
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        <select
          {...reference}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        >
          <option value="">Selecione</option>
          {data &&
            data.map(optionSelect => {
              return (
                <option key={optionSelect.value} value={optionSelect.value}>
                  {optionSelect.text}
                </option>
              );
            })}
        </select>

        {error && (
          <Error title={erroMessage}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </Container>
    </Field>
  );
};

export default Dropdown;
