/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import 'react-day-picker/lib/style.css';
import { FiEdit, FiPlus, FiSave, FiUser } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  Content,
  PanelHoras,
  ActionContainer,
  SearchBox,
  HoraItem,
  FormContainer,
  BodyListHoras,
  HoraHeader,
} from './styles';

import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';
import { Header, HeaderContent } from '../Feriados/styles';

interface Groups {
  id: string;
  name: string;
}

interface Hora {
  id: string;
  name: string;
  domingo: number;
  segunda: number;
  terca: number;
  quarta: number;
  quinta: number;
  sexta: number;
  sabado: number;
}

const schema = yup.object().shape({
  domingoHoras: yup.number().required('Digite o numero de horas'),
  domingoMinutos: yup.number().required('Digite o numero de minutos'),
  segundaHoras: yup.number().required('Digite o numero de horas'),
  segundaMinutos: yup.number().required('Digite o numero de minutos'),
  tercaHoras: yup.number().required('Digite o numero de horas'),
  tercaMinutos: yup.number().required('Digite o numero de minutos'),
  quartaHoras: yup.number().required('Digite o numero de horas'),
  quartaMinutos: yup.number().required('Digite o numero de minutos'),
  quintaHoras: yup.number().required('Digite o numero de horas'),
  quintaMinutos: yup.number().required('Digite o numero de minutos'),
  sextaHoras: yup.number().required('Digite o numero de horas'),
  sextaMinutos: yup.number().required('Digite o numero de minutos'),
  sabadoHoras: yup.number().required('Digite o numero de horas'),
  sabadoMinutos: yup.number().required('Digite o numero de minutos'),
});
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const Horas: React.FC = () => {
  const { addToast } = useToast();
  const query = useQuery();
  const history = useHistory();

  const [horas, setHoras] = useState<Hora[]>([]);
  const [FiltredHoras, setFiltredHoras] = useState<Hora[]>([]);
  const [horaEdit, setHorasEdit] = useState<Hora>({
    id: '',
    name: '',
    domingo: 0,
    segunda: 0,
    terca: 0,
    quarta: 0,
    quinta: 0,
    sexta: 0,
    sabado: 0,
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get('hora');
      setHoras(response.data);
      setFiltredHoras(response.data);
    }
    request();
  }, []);

  useEffect(() => {
    const id = query.get('id');
    if (id && horas) {
      debugger;
      const horaFilter = horas.find(hora => hora.id === id);

      if (horaFilter) {
        setValue('name', horaFilter.name);
        setValue('domingoHoras', (horaFilter.domingo / 60).toFixed(0));
        setValue(
          'domingoMinutos',
          horaEdit.domingo - parseInt((horaEdit.domingo / 60).toFixed(0)) * 60,
        );
        setValue('segundaHoras', (horaFilter.segunda / 60).toFixed(0));
        setValue(
          'segundaMinutos',
          horaEdit.segunda - parseInt((horaEdit.segunda / 60).toFixed(0)) * 60,
        );
        setValue('tercaHoras', (horaFilter.terca / 60).toFixed(0));
        setValue(
          'tercaMinutos',
          horaEdit.terca - parseInt((horaEdit.terca / 60).toFixed(0)) * 60,
        );
        setValue('quartaHoras', (horaFilter.quarta / 60).toFixed(0));
        setValue(
          'quartaMinutos',
          horaEdit.quarta - parseInt((horaEdit.quarta / 60).toFixed(0)) * 60,
        );
        setValue('quintaHoras', (horaFilter.quinta / 60).toFixed(0));
        setValue(
          'quintaMinutos',
          horaEdit.quinta - parseInt((horaEdit.quinta / 60).toFixed(0)) * 60,
        );
        setValue('sextaHoras', (horaFilter.sexta / 60).toFixed(0));
        setValue(
          'sextaMinutos',
          horaEdit.sexta - parseInt((horaEdit.sexta / 60).toFixed(0)) * 60,
        );
        setValue('sabadoHoras', (horaFilter.sabado / 60).toFixed(0));
        setValue(
          'sabadoMinutos',
          horaEdit.sabado - parseInt((horaEdit.sabado / 60).toFixed(0)) * 60,
        );

        setHorasEdit(horaFilter);
      }
    }
  }, [
    query,
    horas,
    setValue,
    horaEdit.domingo,
    horaEdit.segunda,
    horaEdit.terca,
    horaEdit.quarta,
    horaEdit.quinta,
    horaEdit.sexta,
    horaEdit.sabado,
  ]);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      const dataPost = {
        ...horaEdit,
        name: data.name,
        domingo: data.domingoHoras * 60 + data.domingoMinutos,
        segunda: data.segundaHoras * 60 + data.segundaMinutos,
        terca: data.tercaHoras * 60 + data.tercaMinutos,
        quarta: data.quartaHoras * 60 + data.quartaMinutos,
        quinta: data.quintaHoras * 60 + data.quintaMinutos,
        sexta: data.sextaHoras * 60 + data.sextaMinutos,
        sabado: data.sabadoHoras * 60 + data.sabadoMinutos,
      };
      try {
        let response;
        dataPost.id !== ''
          ? (response = await api.put('hora', dataPost))
          : (response = await api.post('hora', dataPost));

        if (dataPost.id) {
          const horasUpdated = horas;
          const indexhoras = horasUpdated.findIndex(
            horaItem => horaItem.id === dataPost.id,
          );
          horasUpdated[indexhoras] = response.data;
          setFiltredHoras([...horasUpdated]);
        } else {
          setFiltredHoras([...FiltredHoras, response.data]);
        }

        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: 'Horario foi salvo com sucesso',
        });
      } catch (error: any) {
        const response = error.response.data;
        if (response.status !== 200) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar horarios',
            description: response.message,
          });
        }
      }
    },
    [addToast, horaEdit, FiltredHoras, horas],
  );

  const handleFindEmployee = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      const filterHora = horas.filter(hora =>
        hora.name.toLowerCase().includes(value.target.value.toLowerCase()),
      );
      setFiltredHoras(filterHora);
    },
    [horas],
  );

  const minutesString = useCallback((minutes: number): string => {
    const result = minutes - parseInt((minutes / 60).toFixed(0)) * 60;
    return `${result}`.padStart(2, '0');
  }, []);

  const handleCreateOrEdit = useCallback(() => {
    history.replace({
      search: '',
    });

    setHorasEdit({
      id: '',
      name: '',
      domingo: 0,
      segunda: 0,
      terca: 0,
      quarta: 0,
      quinta: 0,
      sexta: 0,
      sabado: 0,
    });
  }, [history]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">Cadastro de Carga Horária</div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex row">
              <Input
                erroMessage={errors.name?.message}
                error={!!errors.name?.message}
                placeholder="Nome"
                defaultValue={horaEdit.name}
                reference={register('name')}
                name="name"
              />
            </div>
            <div className="flex row">
              <div className="col-2">
                <div className="label">Domingo</div>
                <div className="flex row">
                  <Input
                    reference={register('domingoHoras')}
                    placeholder="HH"
                    erroMessage={errors.domingo?.message}
                    error={!!errors.domingo?.message}
                    defaultValue={(horaEdit.domingo / 60).toFixed(0)}
                  />
                  <span>:</span>
                  <Input
                    reference={register('domingoMinutos')}
                    placeholder="mm"
                    erroMessage={errors.domingo?.message}
                    error={!!errors.domingo?.message}
                    defaultValue={
                      horaEdit.domingo -
                      parseInt((horaEdit.domingo / 60).toFixed(0)) * 60
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="label">Segunda</div>
                <div className="flex row">
                  <Input
                    reference={register('segundaHoras')}
                    placeholder="HH"
                    erroMessage={errors.segunda?.message}
                    error={!!errors.segunda?.message}
                    defaultValue={horaEdit.segunda}
                  />
                  <span>:</span>
                  <Input
                    reference={register('segundaMinutos')}
                    placeholder="mm"
                    erroMessage={errors.segunda?.message}
                    error={!!errors.segunda?.message}
                    defaultValue={
                      horaEdit.segunda -
                      parseInt((horaEdit.segunda / 60).toFixed(0)) * 60
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="label">Terça</div>
                <div className="flex row">
                  <Input
                    reference={register('tercaHoras')}
                    placeholder="HH"
                    erroMessage={errors.terca?.message}
                    error={!!errors.terca?.message}
                    defaultValue={(horaEdit.terca / 60).toFixed(0)}
                  />
                  <span>:</span>
                  <Input
                    reference={register('tercaMinutos')}
                    placeholder="mm"
                    erroMessage={errors.terca?.message}
                    error={!!errors.terca?.message}
                    defaultValue={
                      horaEdit.terca -
                      parseInt((horaEdit.terca / 60).toFixed(0)) * 60
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="label">Quarta</div>
                <div className="flex row">
                  <Input
                    reference={register('quartaHoras')}
                    placeholder="HH"
                    erroMessage={errors.quarta?.message}
                    error={!!errors.quarta?.message}
                    defaultValue={(horaEdit.quarta / 60).toFixed(0)}
                  />
                  <span>:</span>
                  <Input
                    reference={register('quartaMinutos')}
                    placeholder="mm"
                    erroMessage={errors.quarta?.message}
                    error={!!errors.quarta?.message}
                    defaultValue={
                      horaEdit.quarta -
                      parseInt((horaEdit.quarta / 60).toFixed(0)) * 60
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="label">Quinta</div>
                <div className="flex row">
                  <Input
                    reference={register('quintaHoras')}
                    placeholder="HH"
                    erroMessage={errors.quinta?.message}
                    error={!!errors.quinta?.message}
                    defaultValue={(horaEdit.quinta / 60).toFixed(0)}
                  />
                  <span>:</span>
                  <Input
                    reference={register('quintaMinutos')}
                    placeholder="mm"
                    erroMessage={errors.quinta?.message}
                    error={!!errors.quinta?.message}
                    defaultValue={
                      horaEdit.quinta -
                      parseInt((horaEdit.quinta / 60).toFixed(0)) * 60
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="label">Sexta</div>
                <div className="flex row">
                  <Input
                    reference={register('sextaHoras')}
                    placeholder="HH"
                    erroMessage={errors.sexta?.message}
                    error={!!errors.sexta?.message}
                    defaultValue={(horaEdit.sexta / 60).toFixed(0)}
                  />
                  <span>:</span>
                  <Input
                    reference={register('sextaMinutos')}
                    placeholder="mm"
                    erroMessage={errors.sexta?.message}
                    error={!!errors.sexta?.message}
                    defaultValue={
                      horaEdit.sexta -
                      parseInt((horaEdit.sexta / 60).toFixed(0)) * 60
                    }
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="label">Sabado</div>
                <div className="flex row">
                  <Input
                    reference={register('sabadoHoras')}
                    placeholder="HH"
                    erroMessage={errors.sabado?.message}
                    error={!!errors.sabado?.message}
                    defaultValue={(horaEdit.sabado / 60).toFixed(0)}
                  />
                  <span>:</span>
                  <Input
                    reference={register('sabadoMinutos')}
                    placeholder="mm"
                    erroMessage={errors.sabado?.message}
                    error={!!errors.sabado?.message}
                    defaultValue={
                      horaEdit.sabado -
                      parseInt((horaEdit.sabado / 60).toFixed(0)) * 60
                    }
                  />
                </div>
              </div>
            </div>
            <div className="flex">
              <Button style={{ width: 60, marginLeft: 'auto' }} type="submit">
                <FiSave size={25} color="#fff" />
              </Button>
            </div>
          </form>

          <PanelHoras>
            <ActionContainer>
              <SearchBox>
                <Input
                  name="search"
                  placeholder="Buscar por nome"
                  error={false}
                  onChange={handleFindEmployee}
                  style={{ width: 'auto' }}
                />
              </SearchBox>
              <Button
                onClick={() => handleCreateOrEdit()}
                style={{ width: 60, marginTop: 0 }}
              >
                <FiPlus size={25} color="#fff" />
              </Button>
            </ActionContainer>
            <div className="header">Lista de Carga Horária</div>
            <HoraHeader>
              <div>Nome</div>
              <div>Domingo</div>
              <div>Segunda</div>
              <div>Terça</div>
              <div>Quarta</div>
              <div>Quinta</div>
              <div>Sexta</div>
              <div>Sabado</div>
              <div>açao</div>
            </HoraHeader>
            <BodyListHoras>
              <div className="body">
                {FiltredHoras &&
                  FiltredHoras.map(horasItem => {
                    return (
                      <HoraItem key={horasItem.id}>
                        <div>{horasItem.name}</div>
                        <div>
                          {(horasItem.domingo / 60).toFixed(0)}:
                          {minutesString(horasItem.domingo)}
                        </div>
                        <div>
                          {(horasItem.segunda / 60).toFixed(0)}:
                          {minutesString(horasItem.segunda)}
                        </div>
                        <div>
                          {(horasItem.terca / 60).toFixed(0)}:
                          {minutesString(horasItem.terca)}
                        </div>
                        <div>
                          {(horasItem.quarta / 60).toFixed(0)}:
                          {minutesString(horasItem.quarta)}
                        </div>
                        <div>
                          {(horasItem.quinta / 60).toFixed(0)}:
                          {minutesString(horasItem.quinta)}
                        </div>
                        <div>
                          {(horasItem.sexta / 60).toFixed(0)}:
                          {minutesString(horasItem.sexta)}
                        </div>
                        <div>
                          {(horasItem.sabado / 60).toFixed(0)}:
                          {minutesString(horasItem.sabado)}
                        </div>
                        <div>
                          <Link to={`/hours?id=${horasItem.id}`}>
                            <FiEdit size={15} color="#fff" />
                          </Link>
                        </div>
                      </HoraItem>
                    );
                  })}
              </div>
            </BodyListHoras>
          </PanelHoras>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Horas;
