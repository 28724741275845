/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import 'react-day-picker/lib/style.css';
import { FiEdit, FiPlus, FiSave, FiTrash, FiUser } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  Header,
  HeaderContent,
  Content,
  PanelHoras,
  ActionContainer,
  SearchBox,
  HoraItem,
  FormContainer,
  BodyListHoras,
  HoraHeader,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';

interface Company {
  id: string;
  name: string;
}

const schema = yup.object().shape({
  name: yup.string().required('Digite o nome da empresa'),
});
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const Companies: React.FC = () => {
  const { addToast } = useToast();
  const query = useQuery();
  const history = useHistory();

  const [company, setCompany] = useState<Company[]>([]);
  const [FiltredCompany, setFiltredCompany] = useState<Company[]>([]);
  const [companyEdit, setCompanyEdit] = useState<Company>({
    id: '',
    name: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get('company');
      setCompany(response.data);
      setFiltredCompany(response.data);
    }
    request();
  }, []);

  useEffect(() => {
    const id = query.get('id');

    if (id && company) {
      const companyFilter = company.find(companyItem => companyItem.id === id);
      setValue('name', companyFilter?.name);
      if (companyFilter) {
        setCompanyEdit(companyFilter);
      }
    }
  }, [query, company, setValue]);

  const onSubmit = useCallback(
    async (data): Promise<void> => {
      const dataPost = {
        ...companyEdit,
        name: data.name,
      };
      try {
        dataPost.id !== ''
          ? await api.put('company', dataPost)
          : await api.post('company', dataPost);

        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: 'Empresa foi salva com sucesso',
        });
      } catch (error) {
        const response = error.response.data;
        if (response.status !== 200) {
          addToast({
            type: 'error',
            title: 'Erro ao atualizar horarios',
            description: response.message,
          });
        }
      }
    },
    [addToast, companyEdit],
  );

  const handleFindCompany = useCallback(
    (value: React.ChangeEvent<HTMLInputElement>) => {
      const filterCompany = company.filter(companyItem =>
        companyItem.name
          .toLowerCase()
          .includes(value.target.value.toLowerCase()),
      );
      setFiltredCompany(filterCompany);
    },
    [company],
  );

  const handleCreateOrEdit = useCallback(() => {
    history.replace({
      search: '',
    });

    setCompanyEdit({
      id: '',
      name: '',
    });
  }, [history]);

  const handleDelete = useCallback(
    async id => {
      try {
        await api.delete(`/company/${id}`);

        setFiltredCompany(FiltredCompany.filter(item => item.id !== id));
        setCompany(company.filter(item => item.id !== id));

        addToast({
          type: 'success',
          title: 'Empresa excluida',
          description: 'Empresa excluida com sucesso :)',
        });
      } catch (error) {
        const response = error.response.data;
        addToast({
          type: 'error',
          title: 'Empresa',
          description: response.message || 'Falha ao excluir empresa :(',
        });
      }
    },
    [company, FiltredCompany, addToast],
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">Cadastro de Site</div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex row">
              <Input
                erroMessage={errors.name?.message}
                error={!!errors.name?.message}
                placeholder="Nome"
                defaultValue={companyEdit.name}
                reference={register('name')}
                name="name"
              />
            </div>
            <div className="flex">
              <Button style={{ width: 60, marginLeft: 'auto' }} type="submit">
                <FiSave size={25} color="#fff" />
              </Button>
            </div>
          </form>

          <PanelHoras>
            <ActionContainer>
              <SearchBox>
                <Input
                  name="search"
                  placeholder="Buscar por nome"
                  error={false}
                  onChange={handleFindCompany}
                  style={{ width: 'auto' }}
                />
              </SearchBox>
              <Button
                onClick={() => handleCreateOrEdit()}
                style={{ width: 60, marginTop: 0 }}
              >
                <FiPlus size={25} color="#fff" />
              </Button>
            </ActionContainer>
            <div className="header">Sites Cadastrados</div>
            <HoraHeader>
              <div>Nome</div>
              <div>açao</div>
            </HoraHeader>
            <BodyListHoras>
              <div className="body">
                {FiltredCompany &&
                  FiltredCompany.map(companyItem => {
                    return (
                      <HoraItem key={companyItem.id}>
                        <div>{companyItem.name}</div>

                        <div>
                          <Link to={`/company?id=${companyItem.id}`}>
                            <FiEdit size={15} color="#fff" />
                          </Link>
                          <Link
                            to="/company"
                            onClick={() => {
                              handleDelete(companyItem.id);
                            }}
                          >
                            <FiTrash size={15} color="#fff" />
                          </Link>
                        </div>
                      </HoraItem>
                    );
                  })}
              </div>
            </BodyListHoras>
          </PanelHoras>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Companies;
