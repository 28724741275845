/* eslint-disable no-plusplus */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import 'react-day-picker/lib/style.css';

import format from 'date-fns/format';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  addDays,
  addHours,
  differenceInDays,
  differenceInMinutes,
  endOfMonth,
  getDay,
  startOfMonth,
} from 'date-fns';
import {
  Container,
  Content,
  ActionContainer,
  SearchBox,
  FormContainer,
  SubstituicaoItem,
  BodyListSubstituicao,
  SubstituicaoHeader,
  PanelSubstituicao,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import SideBar from '../../components/SideBar';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import Invalidate from '../../components/Invalidate';
import { Header, HeaderContent } from '../Feriados/styles';

interface Employee {
  id?: string;
  enabled?: boolean;
  name?: string;
  text?: string;
  value?: string;
  cargaHoraria_id?: string;
  company_id?: string;
}

interface Registers {
  date: Date;
  id: string;
  valid: boolean;
}

interface CargaHoraria {
  name: string;
  domingo: number;
  segunda: number;
  terca: number;
  quarta: number;
  quinta: number;
  sexta: number;
  sabado: number;
}

interface Feriado {
  id: string;
  name: string;
  dia: number;
  mes: number;
  ano: number;
  recorrencia: boolean;
  empresa: string;
  company?: { name: string; id: string };
}

const HistoricoAdmin: React.FC = () => {
  const { addToast } = useToast();

  const [registers, setRegisters] = useState<Registers[]>([]);
  const [employeeId, setEmployeeId] = useState('');
  const [dataSelecionada, setDataSelecionada] = useState<Date | undefined>();
  const [idSelecionada, setIdSelecionada] = useState<string>();
  const [listEmployees, setListEmployees] = useState<Employee[]>([]);
  const [showInvalid, setShowInvalid] = useState(false);
  const [cargaHorairaId, setCargaHorariaId] = useState<string | undefined>('');
  const [cargaHoraria, setCargaHoraria] = useState({} as CargaHoraria);
  const [feriado, setFeriado] = useState<Feriado[]>([]);
  const [dateFilter, setDateFilter] = useState<{
    dataInicio: Date;
    dataFim: Date;
  }>({ dataInicio: startOfMonth(new Date()), dataFim: endOfMonth(new Date()) });
  const schema = yup.object().shape({
    dateInicio: yup.date(),
    dateFim: yup.date(),
  });

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  const isHoliday = useCallback(
    (date: Date) => {
      const employeeSelected = listEmployees.find(
        employee => employee.id === employeeId,
      );
      const ehFeriado = feriado.find(item => {
        if (item.company && item.company.id !== employeeSelected?.company_id) {
          return false;
        }
        if (
          item.dia !== parseInt(format(date, 'dd')) ||
          item.mes !== parseInt(format(date, 'MM'))
        ) {
          return false;
        }
        return true;
      });
      return ehFeriado;
    },
    [employeeId, listEmployees, feriado],
  );

  useEffect(() => {
    async function request(): Promise<void> {
      const [responseEmployee, feriados] = await Promise.all([
        api.get<Array<Employee>>('/employee'),
        api.get('/feriado'),
      ]);

      setFeriado(feriados.data);
      const employeesActive = responseEmployee.data.filter(
        item => item.enabled === true,
      );
      const employeesList = employeesActive.map<Employee>(item => {
        return {
          value: item.id,
          text: item.name,
          id: item.id,
          cargaHoraria_id: item.cargaHoraria_id,
          name: item.name,
          company_id: item.company_id,
        };
      });
      setListEmployees(employeesList);
    }
    request();
  }, []);

  useEffect(() => {
    async function request(): Promise<void> {
      if (dateFilter && employeeId) {
        const cargaHorariaResponse = await api.get<CargaHoraria>(
          `/hora/${cargaHorairaId}`,
        );
        setCargaHoraria(cargaHorariaResponse.data);
        const response = await api.get('register/byRange', {
          params: {
            dataInicial: new Date(dateFilter.dataInicio).getTime(),
            dataFinal: new Date(dateFilter.dataFim).getTime(),
            employeeId,
          },
        });
        setRegisters(response.data);
      }
    }
    request();
  }, [cargaHorairaId, dateFilter, employeeId, showInvalid]);

  const getMinutes = useCallback(
    (data: Date): number => {
      switch (data.getDay()) {
        case 0:
          return cargaHoraria.domingo;
        case 1:
          return cargaHoraria.segunda;
        case 2:
          return cargaHoraria.terca;
        case 3:
          return cargaHoraria.quarta;
        case 4:
          return cargaHoraria.quinta;
        case 5:
          return cargaHoraria.sexta;
        case 6:
          return cargaHoraria.sabado;
        default:
          return 0;
      }
    },
    [cargaHoraria],
  );
  const registerAgrupado = useMemo(() => {
    if (!registers) {
      return [];
    }
    const weekday = new Array(7);
    weekday[0] = 'Domingo';
    weekday[1] = 'Segunda';
    weekday[2] = 'Terça';
    weekday[3] = 'Quarta';
    weekday[4] = 'Quinta';
    weekday[5] = 'Sexta';
    weekday[6] = 'Sabado';
    const dias = [];
    const quantidadeDias = differenceInDays(
      dateFilter.dataFim,
      dateFilter.dataInicio,
    );
    for (let index = 0; index <= quantidadeDias; index++) {
      if (registers?.length === 0) {
        break;
      }
      const dataAtual = addDays(addHours(dateFilter.dataInicio, 3), index);
      const cargaHorariaMinutos = getMinutes(dataAtual);
      const registrosDia = registers.filter(
        item =>
          format(new Date(item.date), 'dd/MM/yyyy') ===
            format(dataAtual, 'dd/MM/yyyy') && item.valid === true,
      );
      let totalMinutosTrabalhados = 0;
      let MinutosExtra = 0;

      if (registrosDia && registrosDia.length % 2 === 0) {
        for (
          let indexRegistros = 0;
          indexRegistros < registrosDia.length;
          indexRegistros++
        ) {
          const element = registrosDia[indexRegistros];
          indexRegistros += 1;

          totalMinutosTrabalhados += differenceInMinutes(
            new Date(registrosDia[indexRegistros].date),
            new Date(element.date),
          );
        }
        if (totalMinutosTrabalhados < 0) {
          totalMinutosTrabalhados *= -1;
        }

        MinutosExtra = totalMinutosTrabalhados - cargaHorariaMinutos;
      }

      dias.push({
        registrosDia,
        diaSemana: weekday[getDay(dataAtual)],
        date: dataAtual,
        extra: MinutosExtra,
      });
    }
    return dias;
  }, [registers, dateFilter.dataFim, dateFilter.dataInicio, getMinutes]);

  const handleFindRegisters = useCallback(() => {
    const values = getValues();
    setDateFilter({
      dataInicio: new Date(values.dateInicio),
      dataFim: new Date(values.dateFim),
    });
  }, [getValues]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <ActionContainer>
            <SearchBox>
              <form>
                <div className="flex row">
                  <Dropdown
                    data={
                      listEmployees as Array<{ value: string; text: string }>
                    }
                    label="Funcionario"
                    onChange={e => {
                      setEmployeeId(e.target.value);
                      const employeeSelected = listEmployees?.find(
                        employeeItem => employeeItem.id === e.target.value,
                      );
                      setCargaHorariaId(employeeSelected?.cargaHoraria_id);
                    }}
                  />
                  <Input
                    type="date"
                    label="Data Inicio"
                    error={false}
                    reference={register('dateInicio')}
                    style={{ width: 'auto' }}
                  />
                  <Input
                    type="date"
                    label="Data Fim"
                    error={false}
                    reference={register('dateFim')}
                    style={{ width: 'auto' }}
                  />
                </div>
                <div className="flex">
                  <Button
                    style={{ width: 60, marginLeft: 'auto' }}
                    type="button"
                    onClick={handleFindRegisters}
                  >
                    <FiSearch size={25} color="#fff" />
                  </Button>
                </div>
              </form>
            </SearchBox>
          </ActionContainer>
          <div className="header">Horarios Registrados</div>
          <PanelSubstituicao>
            <SubstituicaoHeader>
              <div>Data</div>
              <div>Dia Semana</div>
              <div>Extra</div>
              <div className="valid">
                <div>
                  <b>entrada</b>
                </div>
                <div>
                  <b>saida</b>
                </div>
              </div>
            </SubstituicaoHeader>
            <BodyListSubstituicao>
              <div className="body">
                {registerAgrupado &&
                  registerAgrupado.map(reg => {
                    return (
                      <SubstituicaoItem key={reg.date.getDate()}>
                        <div>{format(reg.date, 'dd/MM/yyyy')}</div>
                        <div
                          style={
                            reg.diaSemana === 'Domingo' ||
                            reg.diaSemana === 'Sabado'
                              ? { color: '#ff9000' }
                              : {}
                          }
                        >
                          {reg.diaSemana}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          }}
                        >
                          <span style={{ color: '#ff9000' }}>
                            {reg.registrosDia.length !== 4 &&
                              reg.registrosDia.length !== 0 &&
                              `Total de registros: ${reg.registrosDia.length}`}
                          </span>
                          <br />
                          <span>
                            {reg.extra > 10 &&
                              `\n Saldo: ${format(
                                addHours(new Date(reg.extra * 60000), 3),
                                'HH:mm',
                              )}`}

                            {reg.extra < -10 &&
                              !isHoliday(reg.date) &&
                              `\n Saldo: -${format(
                                addHours(new Date(reg.extra * -1 * 60000), 3),
                                'HH:mm',
                              )}`}
                          </span>
                        </div>
                        <div className="valid">
                          {reg.registrosDia &&
                            reg.registrosDia.map(item => (
                              <div key={item.id}>
                                <span>
                                  <Link
                                    to={`#${item.id}`}
                                    onClick={() => {
                                      setIdSelecionada(item.id);
                                      setDataSelecionada(item.date);
                                      setShowInvalid(!showInvalid);
                                    }}
                                  >
                                    {format(new Date(item.date), 'HH:mm')}
                                  </Link>
                                </span>
                              </div>
                            ))}
                        </div>
                      </SubstituicaoItem>
                    );
                  })}
              </div>
            </BodyListSubstituicao>
          </PanelSubstituicao>
        </FormContainer>
      </Content>
      <Invalidate
        closeFunction={() => {
          setShowInvalid(!showInvalid);
        }}
        show={showInvalid}
        date={dataSelecionada}
        employee_id={employeeId}
        id={idSelecionada}
      />
    </Container>
  );
};

export default HistoricoAdmin;
