import L from 'leaflet';
import tierhImg from '../assets/MapPin.png';

const tierhIcon = L.icon({
  iconUrl: tierhImg,

  iconSize: [32, 32],
  iconAnchor: [29, 68],
  popupAnchor: [0, -60],
});

export default tierhIcon;
