// eslint-disable-next-line import/no-extraneous-dependencies
import * as AlertDialog from '@radix-ui/react-alert-dialog';
import React from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import Button from '../Button';

type AlertDialogPapeletaProps = {
  titulo: string;
  filtro: { month: number; year: number };
};

const AlertDialogPapeleta: React.FC<AlertDialogPapeletaProps> = ({
  titulo,
  filtro,
}) => {
  const history = useHistory();
  const downloadPapeletaHandle = async (): Promise<void> => {
    const response = await api.post('/register/downloadPDF', filtro, {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], {
      type: ' application/pdf',
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
    history.goBack();
  };
  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild>
        <Button style={{ width: 100 }} className="Button" type="button">
          {titulo}
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="AlertDialogOverlay" />
        <AlertDialog.Content className="AlertDialogContent">
          <AlertDialog.Title className="AlertDialogTitle">
            Confirmação
          </AlertDialog.Title>
          <AlertDialog.Description className="AlertDialogDescription">
            Declaro que revisei todas as minhas datas de trabalho e respectivos
            horários de entrada e saída apresentados no meu relatório de
            controle de horários. Confirmo que todas as informações estão
            corretas e condizem com os horários efetivamente trabalhados por
            mim. Estou ciente de que este documento será utilizado para fins de
            comprovação de jornada de trabalho e concordo com as informações
            apresentadas. Ao clicar em &quot;Confirmar&quot;, estou assumindo a
            responsabilidade pela veracidade das informações e autorizando a
            impressão do relatório para assinatura.
          </AlertDialog.Description>
          <div style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}>
            <AlertDialog.Cancel asChild>
              <button className="Button mauve" type="button">
                Cancelar
              </button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button
                style={{ width: 100 }}
                className="Button green"
                type="button"
                onClick={downloadPapeletaHandle}
              >
                Confirmar
              </Button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default AlertDialogPapeleta;
