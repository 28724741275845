/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';

import 'react-day-picker/lib/style.css';

import format from 'date-fns/format';
import { FaRegThumbsUp, FaRegThumbsDown } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiSearch } from 'react-icons/fi';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import { padding } from 'polished';
import {
  Container,
  Content,
  ActionContainer,
  SearchBox,
  FormContainer,
  SubstituicaoItem,
  BodyListSubstituicao,
  SubstituicaoHeader,
  PanelSubstituicao,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import SideBar from '../../components/SideBar';
import Substituicao from '../../components/Substituicao';
import Button from '../../components/Button';
import { Header, HeaderContent } from '../Feriados/styles';

interface Employee {
  name: string;
}

interface Registers {
  date: Date;
  id: string;
}

interface Ocurrence {
  description: string;
  type: number;
}

interface Subistituicao {
  approved_by_id: string;
  valid: boolean;
  situacao: string;
  id: string;
  date: Date;
  employee: Employee;
  extraHour: boolean;
  registersValid: Registers[];
  substituition_id: string;
  ocurrence?: Ocurrence[];
}

const HistoricoSubistituicao: React.FC = () => {
  const { addToast } = useToast();

  const [subistituicao, setSubistituicao] = useState<Subistituicao[]>([]);
  const [subistituicaoFilter, setSubistituicaoFilter] = useState<
    Subistituicao[]
  >([]);
  const [dataSelecionada, setDataSelecionada] = useState<Date | undefined>();
  const [idSelecionada, setIdSelecionada] = useState<string>();
  const [showSubstituicao, setShowSubstituicao] = useState<boolean>(false);
  const [geolocaleAtual, setGeolocaleAtual] = useState<{
    latitude: number;
    longitude: number;
  }>({ longitude: 0, latitude: 0 });

  if ('geolocation' in navigator) {
    /* geolocation is available */
  } else {
    alert(
      "I'm sorry, but geolocation services are not supported by your browser.",
    );
  }

  const FindGeolocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(async position => {
      if (geolocaleAtual.latitude !== position.coords.latitude) {
        setGeolocaleAtual({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      }
    });
  }, [geolocaleAtual]);

  const schema = yup.object().shape({
    date: yup.date(),
  });

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.get('register/submission/mylist');
      setSubistituicao(response.data);
      setSubistituicaoFilter(response.data);
      FindGeolocation();
    }
    request();
  }, []);

  const handleFindSubstituicao = useCallback(() => {
    const values = getValues();
    let filterSubstituicao;
    if (values.date !== '') {
      filterSubstituicao = subistituicao.filter(
        substituicaoItem =>
          new Date(substituicaoItem.date) >=
            new Date(`${values.date} 00:00:00z`) &&
          new Date(substituicaoItem.date) <=
            new Date(`${values.date} 23:59:00z`),
      );
    } else {
      filterSubstituicao = subistituicao;
    }
    setSubistituicaoFilter(filterSubstituicao);
  }, [subistituicao, getValues]);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <ActionContainer>
            <SearchBox>
              <form>
                <div className="flex row">
                  <Input
                    type="date"
                    name="date"
                    error={false}
                    reference={register('date')}
                    style={{ width: 'auto' }}
                  />
                </div>
                <div className="flex">
                  <Button
                    style={{ width: 160, marginRight: 'auto', marginLeft: 0 }}
                    onClick={() => {
                      setIdSelecionada('');
                      setDataSelecionada(undefined);
                      setShowSubstituicao(!showSubstituicao);
                    }}
                  >
                    Submeter
                  </Button>
                  <Button
                    style={{ width: 60 }}
                    type="button"
                    onClick={handleFindSubstituicao}
                  >
                    <FiSearch size={25} color="#fff" />
                  </Button>
                </div>
              </form>
            </SearchBox>
          </ActionContainer>
          <div className="header">Pedidos em Avaliação</div>
          <PanelSubstituicao>
            <SubstituicaoHeader>
              <div>Registros</div>
              <div>Data</div>
              <div>Justificativa</div>
              <div>status</div>
            </SubstituicaoHeader>
            <BodyListSubstituicao>
              <div className="body">
                {subistituicaoFilter &&
                  subistituicaoFilter.map(sub => {
                    return (
                      <SubstituicaoItem key={sub.id}>
                        <div>
                          <div className="valid">
                            <div>
                              <b>entrada</b>
                            </div>
                            <div>
                              <b>saida</b>
                            </div>
                            {sub.registersValid.map(item => {
                              return (
                                <div key={item.id}>
                                  {item.id === sub.substituition_id ? (
                                    <span className="label">
                                      {format(new Date(item.date), 'HH:mm')}
                                    </span>
                                  ) : (
                                    <span>
                                      {format(new Date(item.date), 'HH:mm')}
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                          <div>
                            {format(new Date(sub.date), 'dd/MM/yyyy HH:mm')}
                          </div>
                          <div>
                            {sub.ocurrence?.map(ocurrence => {
                              return (
                                <>
                                  {ocurrence.type !== 3 &&
                                    ocurrence.description}
                                </>
                              );
                            })}
                          </div>
                          <div>{sub.situacao}</div>
                        </div>
                      </SubstituicaoItem>
                    );
                  })}
              </div>
            </BodyListSubstituicao>
          </PanelSubstituicao>
        </FormContainer>
      </Content>
      <Substituicao
        closeFunction={() => {
          setShowSubstituicao(!showSubstituicao);
        }}
        show={showSubstituicao}
        dataHora={dataSelecionada}
        id={idSelecionada}
        longitude={geolocaleAtual.longitude}
        latitude={geolocaleAtual.latitude}
      />
    </Container>
  );
};

export default HistoricoSubistituicao;
