/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useCallback, useEffect, useMemo } from 'react';

import 'react-day-picker/lib/style.css';
import { parse, isDate, addHours } from 'date-fns';
import { FiSave } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Container,
  Header,
  HeaderContent,
  Content,
  FormContainer,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Dropdown from '../../components/Dropdown';
import Menu from '../../components/Menu';
import Button from '../../components/Button';
import SideBar from '../../components/SideBar';

interface Groups {
  id: string;
  name: string;
}

export interface Employee {
  id?: string;
  name: string;
  email: string;
  document: string;
  phone?: string;
  zip?: string;
  street?: string;
  district?: string;
  city?: string;
  admissionDate: string | number;
  pis?: string;
  hours: string;
  interval: string;
  company: { id: string };
  company_id?: string;
  group_id?: string;
  group: { id: string };
  user: string;
  enabled: boolean;
  cargaHoraria_id?: string;
  coordenador_id?: string;
  mobile: boolean;
  emailExtraHour?: string;
  cargo?: string;
  function?: string;
}

interface CargaHoraria {
  id: string;
  name: string;
}

interface Company {
  id: string;
  name: string;
}

const parseDateString = (value: string, originalValue: string): number => {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd-MM-yyyy', new Date());

  return new Date(parsedDate).getTime();
};

const schema = yup.object().shape({
  name: yup.string().required('Digite o nome do funcionário'),
  email: yup
    .string()
    .required('Digite o e-mail do funcionário')
    .email('e-mail invalido'),
  document: yup.string().required('Digite o CPF do funcionário').min(11),
  cargaHoraria_id: yup
    .string()
    .required('Informe a carga horaria que o funcionário de seguir'),

  interval: yup.number().required('Informe o tempo de intervalo'),
  company_id: yup.string().required('Informe onde o funcionário vai trabalhar'),
  group_id: yup.string().required('Informe o tipo de usuário'),
  admissionDate: yup
    .string()
    .required('Informe a data de admissão do funcionário'),
  coordenado_id: yup.string(),
  mobile: yup.string(),
  emailExtraHour: yup.string(),
  cargo: yup.string(),
});
const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

const CreateOrEdit: React.FC = () => {
  const { addToast } = useToast();
  const query = useQuery();
  const history = useHistory();

  const [groups, setGroups] = useState<Groups[]>([]);
  const [employee, setEmployee] = useState<Employee>({} as Employee);
  const [loading, setLoading] = useState(true);
  const [coordenador, setCoordenador] = useState<Employee[]>([]);
  const [company, setCompany] = useState<Company[]>([]);

  const [cargaHoraria, setCargaHoraria] = useState<CargaHoraria[]>([]);
  const [employeeEdit, setEmployeeEdit] = useState<Employee>({
    name: '',
    email: '',
    document: '',
    admissionDate: format(new Date(), 'yyyy-MM-dd'),
    hours: '',
    interval: '',
    company: { id: '' },
    group: { id: '' },
    user: '',
    enabled: true,
    mobile: false,
    emailExtraHour: '',
    cargo: '',
  });

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const id = query.get('id');
      if (id) {
        const response = await api.get<Employee>(`/employee/${id}`);
        setEmployee(response.data);
      }
      const responseCargaHoraria = await api.get('/hora');
      setCargaHoraria(responseCargaHoraria.data);
      const responseGroup = await api.get('/group');
      setGroups(responseGroup.data);
      const responseCoordenador = await api.get('/employee/coordenadores');
      if (responseCoordenador.data) {
        setCoordenador(responseCoordenador.data);
      }

      const responseCompany = await api.get('/company');
      if (responseCompany.data) {
        setCompany(responseCompany.data);
      }
      setLoading(false);
    }
    request();
  }, []);

  const permissoes = useMemo(() => {
    return groups.map<{ value: string; text: string }>(groupItem => {
      return {
        value: groupItem.id,
        text: groupItem.name,
      };
    });
  }, [groups]);

  const coordenadoresValues = useMemo(() => {
    return coordenador.map<{ value: string; text: string }>(coordenadorItem => {
      return {
        value: coordenadorItem.id || '',
        text: coordenadorItem.name,
      };
    });
  }, [coordenador]);

  const companyValues = useMemo(() => {
    return company.map<{ value: string; text: string }>(companyItem => {
      return {
        value: companyItem.id || '',
        text: companyItem.name,
      };
    });
  }, [company]);

  const cargaHorariaValues = useMemo(() => {
    return cargaHoraria.map<{ value: string; text: string }>(horarios => {
      return {
        value: horarios.id,
        text: horarios.name,
      };
    });
  }, [cargaHoraria]);

  const onSubmit = useCallback(
    async (data: Employee): Promise<void> => {
      const test = getValues();
      const dataPost = {
        ...employee,
        ...data,
        admissionDate: new Date(data.admissionDate),
      };
      try {
        let responseData: Employee;
        dataPost.id
          ? (responseData = (await api.put('employee', dataPost)).data)
          : (responseData = (await api.post('employee', dataPost)).data);

        addToast({
          type: 'success',
          title: 'Salvo com sucesso',
          description: 'Funcionário foi salvo com sucesso',
        });
        history.goBack();
      } catch (error: any) {
        const response = error.response.data;
        if (response.status !== 200) {
          let mensagem = '';
          switch (response.message) {
            case 'Email address already used.':
              mensagem = 'e-mail já esta sendo usado por outro funcionário :(';
              break;
            case 'Document already used':
              mensagem =
                'Já existe um funcionário com esse email, verifique se o documento esta correto ou se o funcionário já esta cadastrado :(';
              break;
            default:
              break;
          }
          addToast({
            type: 'error',
            title: 'Erro no funcionário',
            description: mensagem,
          });
        }
      }
    },
    [addToast, employee, getValues, history],
  );

  const handleCreateOrEdit = useCallback(() => {
    history.replace({
      search: '',
    });

    setEmployeeEdit({
      id: '',
      name: '',
      email: '',
      document: '',
      admissionDate: format(new Date(), 'yyyy-MM-dd'),
      hours: '',
      interval: '',
      company: { id: '' },
      group: { id: '' },
      user: '',
      enabled: true,
      mobile: false,
      emailExtraHour: '',
      cargo: '',
    });
    setValue('document', '');
  }, [history, setValue]);
  if (loading) {
    return (
      <Container>
        <Header>
          <HeaderContent>
            <Menu />
          </HeaderContent>
        </Header>
        <SideBar />

        <Content>
          <div>Carregando</div>
        </Content>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <div className="header">Cadastro de Funcionário</div>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex row">
              <Input
                erroMessage={errors.name?.message}
                error={!!errors.name?.message}
                placeholder="Nome"
                label="Nome"
                defaultValue={employee.name}
                reference={register('name')}
                name="name"
              />
              <Input
                reference={register('email')}
                label="E-mail"
                defaultValue={employee.email}
                erroMessage={errors.email?.message}
                error={!!errors.email?.message}
                placeholder="E-mail"
              />
            </div>
            <div className="flex row">
              <Input
                reference={register('document')}
                placeholder="Documento"
                label="CPF"
                erroMessage={errors.document?.message}
                error={!!errors.document?.message}
                defaultValue={employee.document}
                mask="999.999.999-99"
              />

              <Dropdown
                data={cargaHorariaValues}
                label="Carga Horaria"
                reference={register('cargaHoraria_id')}
                defaultValue={employee?.cargaHoraria_id}
                erroMessage={errors.cargaHoraria_id?.message}
                error={!!errors.cargaHoraria_id?.message}
              />
            </div>
            <div className="flex row">
              <Input
                defaultValue={employee.interval}
                reference={register('interval')}
                label="Intervalo obrigatório"
                erroMessage={errors.interval?.message}
                error={!!errors.interval?.message}
                placeholder="Intervalo em minutos"
                name="interval"
              />

              <Dropdown
                data={companyValues}
                label="Site"
                reference={register('company_id')}
                erroMessage={errors.company_id?.message}
                error={!!errors.company_id?.message}
                defaultValue={employee.company?.id}
              />
            </div>
            <div className="flex row">
              <Dropdown
                label="Tipo Usuario"
                data={permissoes}
                reference={register('group_id')}
                defaultValue={employee.group?.id}
                erroMessage={errors.group_id?.message}
                error={!!errors.group_id?.message}
              />

              <Input
                label="Data Admissão"
                defaultValue={format(
                  employee.admissionDate
                    ? addHours(new Date(employee.admissionDate), 3)
                    : new Date(),
                  'yyyy-MM-dd',
                )}
                reference={register('admissionDate')}
                erroMessage={errors.admissionDate?.message}
                error={!!errors.admissionDate?.message}
                name="admissionDate"
                type="date"
              />
              <Input
                erroMessage={errors.function?.message}
                error={!!errors.function?.message}
                placeholder="cargo"
                label="Cargo"
                defaultValue={employee.cargo || employee.function}
                reference={register('cargo')}
                name="cargo"
              />
              {/* <CustomDate
                reference={register('admissionDate')}
                erroMessage={errors.admissionDate?.message}
                error={!!errors.admissionDate?.message}
                selected={new Date(employee.admissionDate)}
                onSelect={handleDateSelect} // when day is clicked
                onChange={handleDateChange} // only when value has changed
              /> */}
            </div>
            <div className="flex row">
              <Dropdown
                label="Coordenador"
                data={coordenadoresValues}
                reference={register('coordenador_id')}
                defaultValue={employee.coordenador_id}
                erroMessage={errors.coordenador_id?.message}
                error={!!errors.coordenador_id?.message}
              />
              <Input
                label="Email recebem hora extra"
                defaultValue={employee.emailExtraHour}
                reference={register('emailExtraHour')}
                erroMessage={errors.emailExtraHour?.message}
                error={!!errors.emailExtraHour?.message}
                placeholder="Informe os emails separados por ;"
                name="emailExtraHour"
              />
            </div>
            <div className="flex row">
              <div className="align-bottom">
                <label className="container">
                  <input
                    {...register('mobile')}
                    defaultChecked={employee.mobile}
                    type="checkbox"
                  />
                  Pode usar celular
                  <span className="checkmark" />
                </label>
              </div>
            </div>
            <div className="flex">
              <Button style={{ width: 60, marginLeft: 'auto' }} type="submit">
                <FiSave size={25} color="#fff" />
              </Button>
            </div>
          </form>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default CreateOrEdit;
