import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';
import api from '../services/api';

interface NotificationItem {
  id: string;
  type: number;
  view: boolean;
  message: string;
  created_at: Date;
}

interface NotificationContextData {
  notifications: Array<NotificationItem>;
  total: number;
  view(id: string): Promise<boolean>;
}

const NotificationContext = createContext<NotificationContextData>(
  {} as NotificationContextData,
);

const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<Array<NotificationItem>>(
    [],
  );
  const [total, setTotal] = useState(0);
  useEffect(() => {
    async function request(): Promise<void> {
      const token = localStorage.getItem('@Tierh:token');
      if (token) {
        const response = await api.get('notification');
        setNotifications(response.data.notification);
        setTotal(response.data.total);
      }
    }
    setInterval(request, 60000);
  }, []);

  const view = useCallback(
    async (id: string): Promise<boolean> => {
      const token = localStorage.getItem('@Tierh:token');
      if (token) {
        await api.get(`notification/${id}`);
        setTotal(total - 1);
        return true;
      }
      return false;
    },
    [total],
  );

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        total,
        view,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

function useNotification(): NotificationContextData {
  const context = useContext(NotificationContext);

  return context;
}

export { NotificationProvider, useNotification };
