import React, { useCallback, ChangeEvent } from 'react';
import { FiMail, FiUser, FiLock, FiCamera, FiArrowLeft } from 'react-icons/fi';

import * as Yup from 'yup';
import { useHistory, Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import api from '../../services/api';

import { useToast } from '../../hooks/toast';

import Input from '../../components/Input';
import Button from '../../components/Button';

import { Container, Content, AvatarInput } from './styles';
import { useAuth } from '../../hooks/auth';

interface ProfileFormData {
  name: string;
  email: string;
  old_password: string;
  password: string;
  passwordConfirmation: string;
}

const schema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .required('E-mail obrigatório')
    .email('Digite um e-mail válido'),
  old_password: Yup.string(),
  password: Yup.string().when('old_password', {
    is: val => !!val.length,
    then: Yup.string().required('Campo obrigatório'),
    otherwise: Yup.string(),
  }),
  passwordConfirmation: Yup.string()
    .when('old_password', {
      is: val => !!val.length,
      then: Yup.string().required('Campo obrigatório'),
      otherwise: Yup.string(),
    })
    .oneOf([Yup.ref('password'), null], 'Confirmação incorreta'),
});

const Profile: React.FC = () => {
  const { addToast } = useToast();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ProfileFormData>({
    resolver: yupResolver(schema),
  });

  const { user, updateUser } = useAuth();

  const onSubmit = useCallback(
    async (data: ProfileFormData) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, email, old_password, password } = data;

        const formData = {
          name,
          email,
          ...(old_password
            ? {
                old_password,
                password,
              }
            : {}),
        };

        const response = await api.put('/profile', formData);
        // const responseEmployee = await api.put('/employee', formData);

        updateUser(response.data);

        history.goBack();

        addToast({
          type: 'success',
          title: 'Perfil atualizado!',
          description:
            'Suas informações do perfil foram atualizadas com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro na atualização',
          description: 'Ocorreu um erro ao atualizar perfil, tente novamente.',
        });
      }
    },
    [addToast, history, updateUser],
  );

  const handleAvatarChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const data = new FormData();

        data.append('avatar', e.target.files[0]);

        api.patch('/users/avatar', data).then(response => {
          updateUser(response.data);

          addToast({
            type: 'success',
            title: 'Avatar atualizado!',
          });
        });
      }
    },
    [addToast, updateUser],
  );

  return (
    <Container>
      <header>
        <div>
          <Link to="/dashboard">
            <FiArrowLeft />
          </Link>
        </div>
      </header>

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AvatarInput>
            <img src={user.avatar_url} alt={user.name} />
            <label htmlFor="avatar">
              <FiCamera />

              <input type="file" id="avatar" onChange={handleAvatarChange} />
            </label>
          </AvatarInput>

          <h1>Meu perfil</h1>

          <Input
            name="name"
            erroMessage={errors.name?.message}
            error={!!errors.name?.message}
            icon={FiUser}
            placeholder="Nome"
            defaultValue={user.name}
            reference={register('name')}
          />
          <Input
            containerStyle={{ marginTop: 10 }}
            name="email"
            erroMessage={errors.email?.message}
            error={!!errors.email?.message}
            defaultValue={user.email}
            icon={FiMail}
            placeholder="E-mail"
            reference={register('email')}
          />

          <Input
            containerStyle={{ marginTop: 24 }}
            erroMessage={errors.old_password?.message}
            error={!!errors.old_password?.message}
            name="old_password"
            icon={FiLock}
            type="password"
            placeholder="Senha atual"
            reference={register('old_password')}
          />

          <Input
            containerStyle={{ marginTop: 10 }}
            name="password"
            icon={FiLock}
            type="password"
            placeholder="Nova senha"
            reference={register('password')}
          />

          <Input
            containerStyle={{ marginTop: 10 }}
            reference={register('passwordConfirmation')}
            icon={FiLock}
            type="password"
            placeholder="Confirmar senha"
          />

          <Button type="submit">Confirmar mudanças</Button>
        </form>
      </Content>
    </Container>
  );
};

export default Profile;
