import format from 'date-fns/format';
import React from 'react';

interface ReturnDate {
  time: string;
  dateAtual: Date;
  wish: string;
}

export const useDate = (dateInitial: Date): ReturnDate => {
  const locale = 'pt-BR';
  const [today, setDate] = React.useState(dateInitial); // Save the current date to be able to trigger an update

  React.useEffect(() => {
    const timer = setInterval(() => {
      // Creates an interval which will update the current data every minute
      // This will trigger a rerender every component that uses the useDate hook.
      setDate(new Date());
    }, 60 * 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  }, []);

  const day = today.toLocaleDateString(locale, { weekday: 'long' });
  const date = today;

  const hour = today.getHours();
  const wish = `Good ${
    (hour < 12 && 'Morning') || (hour < 17 && 'Afternoon') || 'Evening'
  }, `;

  const time = format(today, 'HH:mm');

  return {
    dateAtual: date,
    time,
    wish,
  };
};
