import styled from 'styled-components';
// import { shade } from 'polished';

export const Container = styled.div`
  position: absolute;
  max-width: 100vw;
`;

export const Header = styled.header`
  background: #fff;

  img {
    width: 120px;
    height: 29px;
  }
`;

export const HeaderContent = styled.div`
  width: Calc(100vw - 257px);
  margin-left: 240px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  > img {
    height: 30px;
  }

  button {
    background: transparent;
    border: 0;

    svg {
      color: #999591;
      width: 20px;
      height: 20px;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  margin-left: 80px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    a {
      text-decoration: none;
      color: #ff9000;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;

export const Content = styled.main`
  position: relative;
  height: calc(100vh - 60px);
  width: Calc(100% - 240px);
  padding: 10px;
  margin-left: 240px;
  display: flex;
  flex-direction: column;

  .form {
    padding-right: 10px;
  }
`;

export const PanelSubstituicao = styled.div`
  transition: left ease-in-out 1s;
  position: relative;
  width: 100%;
  margin: 20px 0;
  left: 0;

  .header {
    padding: 15px;
    font-size: 22px;
    font-weight: 600;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  a {
    background-color: #005ead;
    padding: 10px 10px;
    margin-top: 25px;
    border-radius: 10px;
    transition: background-color 0.2s;

    &:hover {
      background: #0070ce;
    }
  }
`;

export const SearchBox = styled.div`
  flex: 1;
  margin-right: 10px;
`;

export const BodyListSubstituicao = styled.div`

`;

export const SubstituicaoItem = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 10px;
  justify-content: space-between;

  &:nth-of-type(odd) {
    background: var(--alternateColorRow);
  }

  &:nth-of-type(even) {
    background: var(--alternateColorRowEven);
  }

  & > div {
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 0 10px;
    justify-content: space-between;

    a {
      border: transparent;
      color: var(--labelColor)
    }

    & > div {
      align-items: center;
    }
    & > div.valid {
      align-items: flex-start;

      & > div:last-child {
        min-width: 150px;
        justify-content: flex-start;
      }
    }
  }
  &:hover > .valid {
    height: 30px;
  }
  & + div {
    border-top: solid 1px #e1e1e1;
  }

  button {
    background-color: transparent;
    border: none;
    display: flex;
    font-size: 24px;
    @media(max-width: 1370px){
      font-size: 16px;
    }
  }

  div {
    display: flex;
    min-width: 200px;
    @media(max-width: 1370px){
      min-width: 150px;
      font-size: 14px;
    }

    &:first-child {
      flex: 1;
    }

    &:nth-child(4) {
      @media(max-width: 1370px){
        display: none
      }
    }
    &:nth-child(5) {
      width: 400px;
      @media(max-width: 1370px){
        width: 250px
      }
    }

    &:last-child {
      min-width: 150px;
      justify-content: flex-end;
    }
    a {
      border: transparent;
      border-radius: 5px;
      padding: 5px;
      display: flex;

      color: var(--labelColor);
      transition: background-color 0.2s;


    }
  }
  div.valid {
    display: grid !important;
    max-width: 100px;
    grid-template-columns: repeat(2, 50%);
    transition: all 0.2s;

    span.label {
      color: var(--labelColor);
    }
  }
`;

export const SubstituicaoHeader = styled.div`

  display: flex;
  flex-direction: row;
  height: 50px;
  align-items:center;
  padding: 10px 30px;
  margin: 0 10px;
  position: sticky;
  top: 0px;
  background-color: var(--alternateColorRow);
  border-bottom: 1px solid #fff;
  color: var(--labelColor);



  div {
    display: flex;
    min-width: 200px;
    @media(max-width: 1370px){
      min-width: 150px;
      font-size: 14px;
    }

    &:first-child {
      flex: 1;
    }

    &:nth-child(4) {
      @media(max-width: 1370px){
        display: none
      }
    }

    &:nth-child(5) {
      width: 400px;
      @media(max-width: 1370px){
        width: 250px
      }
    }
    &:last-child {
      min-width: 130px;
      justify-content: center;
    }
  }
`;
export const FormContainer = styled.div`
  padding-right: 15px;
  max-width: 100%;
  margin: 15px 15px;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: var(--panelColor);
  .header {
    border-bottom: solid 1px var(--borderInPanel);
    font-size: 1.6rem;
    margin: 0 -15px;
    padding: 10px 25px;
  }
  form {
    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 2px;
    }
    button {
      margin-left: auto;
    }
  }
`;
