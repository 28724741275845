/* eslint-disable no-debugger */
import { yupResolver } from '@hookform/resolvers/yup';

import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FaRegThumbsDown } from 'react-icons/fa';
import * as yup from 'yup';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import TextArea from '../TextArea';
import { Container, Modal } from './styles';

interface Register {
  show: boolean;
  id?: string;
  employee_id?: string;
  date?: Date;
  closeFunction(): void;
}

const Invalidate: React.FC<Register> = ({
  show,
  id,
  employee_id,
  date,
  closeFunction,
}) => {
  const schema = yup.object().shape({
    description: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const { addToast } = useToast();

  const onSubmit = useCallback(
    async dataValue => {
      try {
        if (employee_id === '') {
          addToast({
            type: 'error',
            title: 'ocorreu um erro',
            description: 'erro interno ao reprovado',
          });
          throw new Error('não consegui identificar o usuario');
        }
        const response = await api.post('register/submission/reprove', {
          id,
          employee_id,
          date,
          justificativa: dataValue.description,
        });
        addToast({
          type: 'success',
          title: 'salvo com sucesso',
          description: 'Registro reprovado',
        });
        closeFunction();
      } catch (error) {
        const response = error.response.data;
        console.log(response);
        addToast({
          type: 'error',
          title: 'ocorreu um erro',
          description: response.message || 'erro interno ao reprovado',
        });
      }
    },
    [id, date, employee_id, closeFunction, addToast],
  );

  return (
    <Container Show={show}>
      <Modal Show={show}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <TextArea
              label="Justificativa"
              erroMessage={errors.description?.message}
              error={!!errors.description?.message}
              reference={register('description')}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <button className="danger" type="submit">
              <FaRegThumbsDown size={24} />
            </button>
          </div>
          <div className="flex justify-end">
            <button type="button" onClick={closeFunction}>
              Sair
            </button>
          </div>
        </form>
      </Modal>
    </Container>
  );
};
export default Invalidate;
