/* eslint-disable no-plusplus */
/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import 'react-day-picker/lib/style.css';

import format from 'date-fns/format';
import { FaRegThumbsUp, FaRegThumbsDown, FaFileImport } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiSearch } from 'react-icons/fi';
import setMinutes from 'date-fns/setMinutes';
import setHours from 'date-fns/setHours';
import { useHistory } from 'react-router-dom';
import { addDays } from 'date-fns';
import {
  Container,
  Content,
  ActionContainer,
  SearchBox,
  FormContainer,
  SubstituicaoItem,
  BodyListSubstituicao,
  SubstituicaoHeader,
  PanelSubstituicao,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import { useToast } from '../../hooks/toast';
import Menu from '../../components/Menu';
import SideBar from '../../components/SideBar';
import Substituicao from '../../components/Substituicao';
import Button from '../../components/Button';
import { Header, HeaderContent } from '../Feriados/styles';
import AlertDialogPapeleta from '../../components/Dialog';

interface Employee {
  name: string;
}

interface Registers {
  date: Date;
  id: string;
  valid: boolean;
}

const Historico: React.FC = () => {
  const { addToast } = useToast();

  const [registers, setRegisters] = useState<Registers[]>([]);
  const [dateFilter, setDateFilter] = useState<{ month: number; year: number }>(
    { month: new Date().getMonth(), year: new Date().getFullYear() },
  );
  const schema = yup.object().shape({
    date: yup.date(),
  });

  const { register, getValues } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const response = await api.post('register/month', dateFilter);
      setRegisters(response.data.registers);
    }
    request();
  }, [dateFilter]);

  const registerAgrupado = useMemo(() => {
    const weekday = new Array(7);
    weekday[0] = 'Domingo';
    weekday[1] = 'Segunda';
    weekday[2] = 'Terça';
    weekday[3] = 'Quarta';
    weekday[4] = 'Quinta';
    weekday[5] = 'Sexta';
    weekday[6] = 'Sabado';
    const dias = [];
    for (let index = 1; index <= 31; index++) {
      if (registers.length === 0) {
        break;
      }
      const registrosDia = registers.filter(
        item => new Date(item.date).getDate() === index && item.valid === true,
      );
      dias.push({
        registrosDia,
        diaSemana:
          weekday[
            new Date(dateFilter.year, dateFilter.month - 1, index).getDay()
          ],
        date: new Date(dateFilter.year, dateFilter.month - 1, index),
      });
    }
    return dias;
  }, [registers, dateFilter]);

  const handleFindRegisters = useCallback(() => {
    const values = getValues();
    setDateFilter({
      month: values.date.split('-')[1],
      year: values.date.split('-')[0],
    });
  }, [getValues]);

  const history = useHistory();

  const handleClick = (): void => {
    history.push(`/history/${dateFilter.month}-${dateFilter.year}`);
  };

  console.log(
    new Date(dateFilter.year, dateFilter.month - 1) < addDays(new Date(), -28),
    new Date(dateFilter.year, dateFilter.month - 1),
    addDays(new Date(), -28),
  );

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <ActionContainer>
            <SearchBox>
              <form>
                <div className="flex row">
                  <Input
                    type="month"
                    name="date"
                    error={false}
                    reference={register('date')}
                    style={{ width: 'auto' }}
                  />
                </div>
                <div className="flex" style={{ justifyContent: 'flex-end' }}>
                  {dateFilter.year &&
                    new Date(dateFilter.year, dateFilter.month - 1) <
                      addDays(new Date(), -28) && (
                      <Button
                        style={{ width: 60, marginLeft: 10 }}
                        type="button"
                        onClick={handleClick}
                      >
                        <FaFileImport size={25} color="#fff" />
                      </Button>
                    )}
                  <Button
                    style={{ width: 60, marginLeft: 10 }}
                    type="button"
                    onClick={handleFindRegisters}
                  >
                    <FiSearch size={25} color="#fff" />
                  </Button>
                </div>
              </form>
            </SearchBox>
          </ActionContainer>
          <div className="header">Histórico de Registros</div>
          <PanelSubstituicao>
            <SubstituicaoHeader>
              <div>Data</div>
              <div>Dia Semana</div>
              <div className="valid">
                <div>
                  <b>entrada</b>
                </div>
                <div>
                  <b>saida</b>
                </div>
              </div>
            </SubstituicaoHeader>
            <BodyListSubstituicao>
              <div className="body">
                {registerAgrupado &&
                  registerAgrupado.map(reg => {
                    return (
                      <SubstituicaoItem key={reg.date.getDate()}>
                        <div
                          style={
                            reg.diaSemana === 'Domingo' ? { color: 'red' } : {}
                          }
                        >
                          {format(reg.date, 'dd/MM/yyyy')}
                        </div>
                        <div
                          style={
                            reg.diaSemana === 'Domingo' ? { color: 'red' } : {}
                          }
                        >
                          {reg.diaSemana}
                        </div>
                        <div className="valid">
                          {reg.registrosDia &&
                            reg.registrosDia.map(item => (
                              <div key={item.id}>
                                <span>
                                  {format(new Date(item.date), 'HH:mm')}
                                </span>
                              </div>
                            ))}
                        </div>
                      </SubstituicaoItem>
                    );
                  })}
              </div>
            </BodyListSubstituicao>
          </PanelSubstituicao>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Historico;
