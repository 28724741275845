/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-debugger */
/* eslint-disable jsx-a11y/anchor-is-valid */
import format from 'date-fns/format';
import React, { useCallback, useState } from 'react';
import {
  FaDownload,
  FaExclamation,
  FaRegThumbsDown,
  FaRegThumbsUp,
} from 'react-icons/fa';
import { FiBell, FiPower } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/auth';
import { useNotification } from '../../hooks/notification';
import api from '../../services/api';
import { Container } from './styles';

const Menu: React.FC = () => {
  const { signOut } = useAuth();
  const { notifications, total, view } = useNotification();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const handleDownload = useCallback(async () => {
    const response = await api.get('register/download', {
      responseType: 'blob',
    });
    const blob = new Blob([response.data], {
      type: ' application/zip',
    });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }, []);

  const handleView = useCallback(
    (item: any) => {
      view(item.id);
      if (item.type === 3) {
        handleDownload();
      }
    },
    [view, handleDownload],
  );

  const handleTypeNotification = useCallback(item => {
    switch (item.type) {
      case 1:
        return <FaRegThumbsUp size={24} />;
      case 2:
        return <FaRegThumbsDown size={24} />;
      case 3:
        return <FaDownload size={24} />;
      default:
        return <FaExclamation size={24} />;
    }
  }, []);

  return (
    <Container>
      <ul className="nav navbar-nav navbar-right">
        <li className={`dropdown ${open ? 'open' : ''}`}>
          <div className="iconeNotification" onClick={handleOpen}>
            <FiBell color="#000" size={24} />
            {total !== 0 && <span className="icon-button__badge">{total}</span>}
          </div>
          <ul className="dropdown-menu dropdown-lg dropdown-content">
            <div
              className="slimScrollDiv"
              style={{
                position: 'relative',
                overflow: 'hidden',
                width: 'auto',
                maxHeight: 250,
                minHeight: 80,
              }}
            >
              <li
                className="slimscroll dropdown-notifications"
                style={{
                  overflow: 'hidden',
                  width: 'auto',
                  maxHeight: 250,
                  minHeight: 80,
                }}
              >
                <ul
                  className="list-unstyled dropdown-oc"
                  style={{ overflowX: 'auto', maxHeight: 250 }}
                >
                  {notifications.length > 0 ? (
                    notifications.map(item => {
                      return (
                        <li>
                          <Link
                            to="#"
                            className={item.view ? 'notView' : ''}
                            onClick={() => handleView(item)}
                          >
                            <span className="notification-badge bg-info">
                              {handleTypeNotification(item)}
                            </span>
                            <span className="notification-info">
                              <span className="notification-info">
                                {item.message}
                              </span>
                              <small className="notification-date">
                                {format(
                                  new Date(item.created_at),
                                  'dd/MM/yyyy HH:mm',
                                )}
                              </small>
                            </span>
                          </Link>
                        </li>
                      );
                    })
                  ) : (
                    <li>
                      <Link to="#">
                        <span className="notification-badge bg-info" />
                        <span className="notification-info">
                          <span className="notification-info">
                            Não tem notificações
                          </span>
                          <small className="notification-date" />
                        </span>
                      </Link>
                    </li>
                  )}
                </ul>
                <div
                  className="slimScrollRail"
                  style={{
                    width: 6,
                    height: '100%',
                    position: 'absolute',
                    top: 0,
                    display: 'none',
                    borderRadius: 0,
                    background: 'rgb(51, 51, 51)',
                    opacity: 0.2,
                    zIndex: 90,
                    right: 0,
                  }}
                />
              </li>
            </div>
          </ul>
        </li>
      </ul>
      <button
        type="button"
        onClick={() => {
          signOut();
        }}
      >
        <FiPower color="#ff9000" />
      </button>
    </Container>
  );
};

export default Menu;
