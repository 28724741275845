import styled from 'styled-components';

export const SidebarContainer = styled.aside`
  position: fixed;
  overflow-y: auto;
  background-color: #2a2d36;
  width: 240px;

  height: 100%;
  top: 0px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 1px 0px 20px rgb(0 0 0 / 8%);
  box-shadow: 1px 0px 20px rgb(0 0 0 / 8%);
`;

export const Logo = styled.div`
  background-color: #fff;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: center;
  padding: 10px 0;

  img {
    height: 35px;
    width: 160px;
  }
`;

export const Scroll = styled.div`
  height: 100%;
  position: relative;
`;

export const Nav = styled.nav`
  display: block;

  overflow-y: hidden;
  ul {
    margin: 0px;
    padding: 40px 0;

    li {
      width: 240px;
      list-style: none;
      padding: 10px 5px;
      a {
        height: 28px;
        padding: 10px 15px;
        color: #fff;
        text-decoration: none;
      }
    }
    .header {
      padding-top: 30px;
      padding-left: 10px;
      color: #737580;
      cursor: default;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;

  img {
    width: 56px;
    height: 56px;
    border-radius: 50%;
  }

  div {
    display: flex;
    justify-content: left;
    flex-direction: column;
    padding-top: 10px;
    line-height: 24px;

    span {
      color: #f4ede8;
    }

    a {
      text-decoration: none;
      color: #737580;
      transition: opacity 0.5s;

      &:hover {
        opacity: 0.7;
      }
    }
  }
`;
