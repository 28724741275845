/* eslint-disable no-debugger */
/* eslint-disable radix */
import React, { useState, useEffect, useCallback } from 'react';

import 'react-day-picker/lib/style.css';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FiSearch } from 'react-icons/fi';
import { addDays, format } from 'date-fns';
import {
  Container,
  Content,
  ActionContainer,
  SearchBox,
  FormContainer,
  SubstituicaoItem,
  BodyListSubstituicao,
  SubstituicaoHeader,
  PanelSubstituicao,
} from './styles';

import api from '../../services/api';
import Input from '../../components/Input';
import Menu from '../../components/Menu';
import SideBar from '../../components/SideBar';
import Button from '../../components/Button';
import Dropdown from '../../components/Dropdown';
import { Header, HeaderContent } from '../Feriados/styles';

interface Employee {
  name: string;
}

interface Registers {
  latitude: string;
  longitude: string;
  date: Date;
  id: string;
  ip: string;
}

interface Ocurrence {
  id: string;
  description: string;
  register_id: string;
  employee_id: string;
  type: number;
  register: Registers;
  employee: Employee;
  created_at: Date;
}

const Ocorrencias: React.FC = () => {
  const [listOcorrencias, setListOcorrencias] = useState<Array<Ocurrence>>([]);
  const [listEmployees, setListEmployees] = useState();

  const schema = yup.object().shape({
    startDate: yup.date(),
    endDate: yup.date(),
    tipo: yup.number(),
    employee_id: yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    async function request(): Promise<void> {
      const [response, responseEmployee] = await Promise.all([
        api.post('register/ocorrencias', {
          startDate: addDays(new Date(), -30),
          endDate: new Date(),
        }),
        api.get('/employee'),
      ]);
      const employeesActive = responseEmployee.data.filter(
        (item: any) => item.enabled === true,
      );
      const employeesList = employeesActive.map((item: any) => {
        return { value: item.id, text: item.name };
      });
      setListEmployees(employeesList);

      setListOcorrencias(response.data);
    }
    request();
  }, []);

  const filterOcurrenceHandle = useCallback(async data => {
    const response = await api.post('register/ocorrencias', data);
    setListOcorrencias(response.data);
  }, []);

  const tiposRender = useCallback((value): string => {
    switch (value) {
      case 1:
        return 'Hora Extra';
      case 2:
        return 'Submissão';
      case 3:
        return 'IP invalido';
      case 5:
        return 'Atestado';
      default:
        return 'Tipo invalido';
    }
  }, []);

  return (
    <Container>
      <Header>
        <HeaderContent>
          <Menu />
        </HeaderContent>
      </Header>
      <SideBar />

      <Content>
        <FormContainer>
          <ActionContainer>
            <SearchBox>
              <form onSubmit={handleSubmit(filterOcurrenceHandle)}>
                <div className="flex row">
                  <Dropdown
                    data={[
                      { text: 'Submissão', value: '2' },
                      { text: 'Hora extra', value: '1' },
                      { text: 'IP Invalido', value: '3' },
                      { text: 'Atestado', value: '5' },
                    ]}
                    label="tipo"
                    reference={register('tipo')}
                  />
                  <Dropdown
                    data={listEmployees}
                    label="Funcionario"
                    reference={register('employee_id')}
                  />
                  <Input
                    type="date"
                    name="startDate"
                    label="Data Inicio"
                    error={false}
                    reference={register('startDate')}
                    style={{ width: 'auto' }}
                  />
                  <Input
                    type="date"
                    name="endDate"
                    label="Data Fim"
                    error={false}
                    reference={register('endDate')}
                    style={{ width: 'auto' }}
                  />
                </div>
                <div className="flex">
                  <Button style={{ width: 60 }} type="submit">
                    <FiSearch size={25} color="#fff" />
                  </Button>
                </div>
              </form>
            </SearchBox>
          </ActionContainer>
          <div className="header">Ocorrencias</div>
          <PanelSubstituicao>
            <SubstituicaoHeader>
              <div>Funcionario</div>
              <div>Tipo</div>
              <div>Justificativa</div>
              <div>Registro</div>
            </SubstituicaoHeader>
            <BodyListSubstituicao>
              <div className="body">
                {listOcorrencias &&
                  listOcorrencias.map(item => {
                    return (
                      <SubstituicaoItem key={item.id}>
                        <div>
                          <div>{item.employee.name}</div>
                          <div>{tiposRender(item.type)}</div>
                          <div>
                            {item.description}
                            {item.type === 3 &&
                              ` IP: ${item.register.ip} no local aproximado`}
                            {item.type === 3 && (
                              <a
                                href={`https://www.google.com/maps/search/?api=1&query=${item.register.latitude},${item.register.longitude}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                abrir no mapa
                              </a>
                            )}
                          </div>
                          <div>
                            {format(
                              new Date(item.register.date),
                              'dd/MM/yyyy HH:mm',
                            )}
                          </div>
                        </div>
                      </SubstituicaoItem>
                    );
                  })}
              </div>
            </BodyListSubstituicao>
          </PanelSubstituicao>
        </FormContainer>
      </Content>
    </Container>
  );
};

export default Ocorrencias;
